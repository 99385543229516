import {
    AbstractIdleState,
    AbstractPointerDownState,
    DisplayContext,
    DRAGGING_START_THRESHOLD,
    DraggingState,
    InteractionState
} from './common';
import {Graphics, InteractionEvent} from 'pixi.js';
import {calculateBoundingBox, difference, distance, Point, sum} from '../../geometry';
import {Selection} from '../data';
import {Seat} from '../../types';
import {logThrottled} from '../../log';

export class SelectionIdleState extends AbstractIdleState {

    constructor(private selection: Selection | undefined) {
        super();
    }

    onPointerDown(context: DisplayContext, origin: Point, event: InteractionEvent): InteractionState {
        // Der Benutzer interagiert evtl. mit den handles der Auswahl.
        if (this.selection && (this.selection.id === event.target.name)) {
            return new SelectionRotateState(origin, this.selection);
        }

        const pickedSeat = context.dataManager.pickSeat(context.scene.convertToViewportCoords(origin));

        return new SelectionPointerDownState(origin, this.selection, pickedSeat);
    }

    onEviction(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

}

class SelectionPointerDownState extends AbstractPointerDownState {

    constructor(
        origin: Point,
        private selection: Selection | undefined,
        private pickedSeat: Readonly<Seat> | undefined
    ) {
        super(origin);
    }

    onPointerUp(context: DisplayContext): InteractionState {
        // Wenn der Benutzer "ins Leere" geklickt hat, also im vorausgehenden state erfolgte das
        // pointer-down event nicht über einem Seat, dann die aktuelle Auswahl aufheben.
        if (!this.pickedSeat) {
            context.dataManager.clearCurrentSelection();
            return new SelectionIdleState(undefined);
        }

        // Den angeklickten Seat zur neuen Auswahl machen.
        return new SelectionIdleState(context.dataManager.selectSeats([this.pickedSeat]));
    }

    onPointerMove(context: DisplayContext, current: Point): InteractionState {
        // Nur wenn eine bestimmte Auslenkung festgestellt wird eine Aktion ausführen,
        // um unbeabsichtigte Manipulation durch "Zucken" zu unterdrücken.
        if (distance(this.origin, current) < DRAGGING_START_THRESHOLD) {
            return this;
        }

        // Wenn der Benutzer "ins Leere" geklickt hat, also im vorausgehenden state erfolgte das
        // pointer-down event nicht über einem Seat, gehen wir zur Marquee Auswahl über.
        if (!this.pickedSeat) {
            return new SelectionMarqueeState(this.origin, this.selection);
        }

        // Falls der angeklickte Seat kein Teil der aktuellen Auswahl ist diesen zur neuen Auswahl machen.
        if (!this.selection?.contains(this.pickedSeat)) {
            this.selection = context.dataManager.selectSeats([this.pickedSeat]);
        }

        // übergehen zum Verschieben der Auswahl
        return new SelectionMoveState(this.origin, this.selection);
    }
}

export class SelectionMarqueeState extends DraggingState {

    private readonly marquee = new Graphics();

    private readonly marqueeColor = 0x0000FF;

    constructor(origin: Point, private selection: Selection | undefined) {
        super(origin);
    }

    protected update(context: DisplayContext): InteractionState {
        if (!this.marquee.parent) {
            context.scene.overlay.root.addChild(this.marquee);
        }

        const bb = calculateBoundingBox([this.origin, this.current]);

        this.marquee.clear();
        this.marquee.lineStyle(1, this.marqueeColor, 1);
        this.marquee.beginFill(this.marqueeColor, 1 / 25);
        this.marquee.drawRect(bb.xmin, bb.ymin, bb.xmax - bb.xmin, bb.ymax - bb.ymin);

        return this;
    }

    protected finish(context: DisplayContext): InteractionState {
        context.scene.overlay.root.removeChild(this.marquee);

        const localOrigin = context.scene.convertToViewportCoords(this.origin);
        const localControl = context.scene.convertToViewportCoords(this.current);
        const bb = calculateBoundingBox([localOrigin, localControl]);

        if (this.selection) {
            context.dataManager.clearCurrentSelection();
        }

        return new SelectionIdleState(context.dataManager.selectSeatsByMarquee(bb));
    }

    protected abort(context: DisplayContext): void {
        context.scene.overlay.root.removeChild(this.marquee);
    }

}

class SelectionMoveState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Verschiebung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Verschiebt die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Verschiebung "abgeschlossen" ist.
     */
    private moveSelection(context: DisplayContext, commitTransform = false): void {
        const localOrigin = context.scene.convertToViewportCoords(this.origin);
        const localCurrent = context.scene.convertToViewportCoords(this.current);

        // Da es unwahrscheinlich ist, dass der Benutzer beim Verschieben der Auswahl diese
        // genau mittig geklickt hat, können wir nicht einfach die Auswahl auf die aktuelle
        // Positions des pointer verschieben, da dies den Effekt hätte, das die Auswahl
        // abrupt auf die Position des pointer springt. Stattdessen addieren wir einfach den
        // offset zwischen aktueller und anfänglicher Position zum ursprünglichen Mittelpunkt
        // der bounding box der Auswahl.
        this.selection.move(sum(
            this.selection.boundingBox.center,
            difference(localCurrent, localOrigin)
        ));

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

    protected finish(context: DisplayContext): InteractionState {
        this.moveSelection(context, true);
        return new SelectionIdleState(this.selection);
    }

    protected update(context: DisplayContext): InteractionState {
        this.moveSelection(context);
        return this;
    }

}

class SelectionRotateState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Drehung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Dreht die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Drehung "abgeschlossen" ist.
     */
    private rotateSelection(context: DisplayContext, commitTransform = false): void {
        // TODO
        logThrottled(`ROTATE!: ${this.current}`,);

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

    protected finish(context: DisplayContext): InteractionState {
        this.rotateSelection(context, true);
        return new SelectionIdleState(this.selection);
    }

    protected update(context: DisplayContext): InteractionState {
        this.rotateSelection(context);
        return this;
    }

}
