import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {parse} from 'query-string';

/**
 * Liefert die Parameter des aktuellen query string.
 */
export function useQueryParams() {
    const {search} = useLocation();

    return useMemo(() => parse(search), [search]);
}
