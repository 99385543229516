import * as axios from 'axios';
import {AxiosInstance, AxiosResponse} from 'axios';
import {
    Cart,
    SeatResponse,
    SeatSelectionResponse,
    SetsMenuResponse,
    SubmitResponse,
    VoucherRequest
} from '../model/Seating';
import {PytDates} from '../model/PytExtensions';

interface SeatSelectionRequest {
    // Aktuell unterscheidet sich die Struktur der Sitzplatz Daten,
    // die in der response der API zurückgegeben werden von der,
    // die bei einem update, im request erwartet werden, daher ist ein
    // vor dem request explizites mapping auf diese Struktur notwendig.
    seats: {
        publicId: string
        setId: string
        // Werte sind immer undefined, daher hier einfach als Gedankenstütze.
        // Hängt wahrscheinlich mit der Problematik der zusammen, dass aktuell
        // Auswahlen über verschiedene EventParts nicht funktionieren.
        comboSetId: undefined
        itemDefinition?: string
        firstName?: string
        lastName?: string
    }[];
    initial: boolean;
}

/**
 * Klasse für die Kommunikation mit der API.
 */
class HttpService {
    API_CLIENT: AxiosInstance;
    slug: string;

    constructor() {
        this.slug = '';
        this.API_CLIENT = axios.default.create({
            baseURL: `${process.env.REACT_APP_API_BASE_URL}/seatingNew/`,
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        });
    }

    // TODO Refactoren von manuellen Callbacks zu Promises?
    configure(
        loadingCallback: (isLoading: boolean, url?: string) => void,
        successCallback: (response: AxiosResponse) => void,
        errorCallback: (response: AxiosResponse) => void
    ) {
        const $this = this;
        this.API_CLIENT.interceptors.response.use(function (response) {
            loadingCallback(false);
            successCallback(response);
            if (!$this.getCookieValue('lmticketshop')) {
                document.body.innerHTML = 'App mit unzulässiger Domain aufgerufen';
                return Promise.reject('wrong domain');
            }
            return response;
        }, error => {
            loadingCallback(false);
            errorCallback(error.response);
            return Promise.reject(error);
        });
        this.API_CLIENT.interceptors.request.use((config) => {
            loadingCallback(true, config.url);
            return config;
        }, (error) => {
            loadingCallback(false);
            return Promise.reject(error);
        });
    }

    getSlug(): string {
        return this.slug;
    }

    setSlug(slug: string): void {
        this.slug = slug;
    }

    getCookieValue(name: string): string {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    setCookieValue(name: string, value: string, days: number): void {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    async getSeatResponse(setId: string, filter: string | undefined = undefined, abortSignal?: AbortSignal): Promise<SeatResponse> {
        return (await this.API_CLIENT.post<SeatResponse>(`${this.slug}/seat-views`, {
            setId: setId,
            filter: filter
        }, {signal: abortSignal})).data;
    }

    async getSets(): Promise<SetsMenuResponse> {
        return (await this.API_CLIENT.get<SetsMenuResponse>(`${this.slug}/sets`)).data;
    }

    /**
     * Dient dazu den Warenkorb zu aktualisieren und abzuholen, denn es gibt seitens
     * der API keine Möglichkeit den Warenkorb non-destruktiv abzurufen.
     *
     * @param cart
     * @param initialise flag, ob durch diesen request der server-seitige
     *  Zustand des Warenkorbs (re)initialisiert werden soll.
     */
    private async postCart(cart: Cart, initialise: boolean): Promise<Cart> {
        const request: SeatSelectionRequest = {
            // Die "removed" property existiert nur client-seitig, daher dürfen items,
            // die entfernt werden sollen, nicht mitgesendet werden.
            seats: cart.filter(i => !i.removed).map(i => ({
                publicId: i.publicId,
                setId: i.setId,
                comboSetId: undefined,
                itemDefinition: i.selectedItemDefinition,
                firstName: i.firstName,
                lastName: i.lastName,
            })),
            initial: initialise
        };

        const response = await this.API_CLIENT.post<SeatSelectionResponse, AxiosResponse<SeatSelectionResponse>, SeatSelectionRequest>(`${this.slug}/seat-selection`, request);

        // Die "status" property existiert auf Seit der API nicht, sondern wird lediglich client-seitig verwendet.
        return response.data.itemsForSeats.map(i => ({...i, status: 'SELECTED'}));
    }

    /**
     * Server-seitigen Zustand des Warenkorbs (re)initialisieren.
     */
    async initCart(cart: Cart): Promise<Cart> {
        return this.postCart(cart, true);
    }

    /**
     * Warenkorb aktualisieren.
     */
    async updateCart(newCart: Cart): Promise<Cart> {
        return this.postCart(newCart, false);
    }

    /**
     * Submitted einen Warenkorb mit Plätzen an die API für eine Fortführung des Kaufprozesses im regulären Shop.
     */
    async postSubmit(): Promise<SubmitResponse> {
        return (await this.API_CLIENT.post<SubmitResponse>(`${this.slug}/submit`)).data;
    }

    async postCode(submitData: VoucherRequest): Promise<{}> {
        return (await this.API_CLIENT.post<{}>(`${this.slug}/voucher`, submitData)).data;
    }

    async postClearSession(): Promise<{}> {
        return (await this.API_CLIENT.get<{}>(`${this.slug}/clear-session`)).data;
    }

    // PYT

    async getPytDates(setId: string): Promise<PytDates> {
        return (await this.API_CLIENT.post<PytDates>(`${this.slug}/pyt-dates`, {setId: setId})).data;
    }

}

export const api = new HttpService();

export default api;
