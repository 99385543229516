import React from 'react';
import './SetsView.scss';
import {SetsMenuEntry} from '../model/Seating';
import {useRecoilValue} from 'recoil';
import {matrixConfigState, selectedSetIdState} from '../state';

type SetsViewProps = {
    sets: SetsMenuEntry[];
    selectCard: (setId: string) => void;
};

/**
 * Komponente, welche die "Sets" (Eventteile oder zusammenhängende Eventteile, welche einen Saalplan umfassen)
 * darstellt.
 *
 * @param sets
 * @param selectCard
 * @constructor
 */
const SetsView = ({sets, selectCard}: SetsViewProps): React.ReactElement => {
    const selectedSetId = useRecoilValue(selectedSetIdState);
    const matrixConfig = useRecoilValue(matrixConfigState);

    // FIXME ??????????????????????????????????????????????????????????????????????????????????????????????????????????
    let noticeIndex = 1;
    let noticeIndex2 = 1;

    return (
        <>
            {matrixConfig?.mode === 'normal' && (
                <>
                    <h3 className="pb-1 pb-2 px-1 tertiary">Bitte Zeitraum auswählen</h3>
                    <div className="container-fluid row g-2 mb-4" id="sets">
                        {sets?.map((entry, index) => (
                            <div className="col mt-0 p-0 m-1" key={`set-${index}`}>
                                <ul className="list-group" id="set-menu">
                                    {matrixConfig?.mode === 'normal' && (
                                        <li className="list-group-item disabled card-item card-item-title p-1">
                                            <span>{entry.title}{entry.notice && (
                                                <sup>&nbsp;*{noticeIndex++}</sup>
                                            )}</span>
                                        </li>
                                    )}
                                    {entry?.entries?.map((subEntry, subIndex) => (
                                        <li className={`list-group-item card-item p-1 ${selectedSetId === subEntry.setId ? 'active' : ''}`}
                                            key={`cardsub-${index}-${subIndex}`}

                                            onClick={() => {
                                                selectCard(subEntry.setId)
                                            }}>
                                            <span>{subEntry.shortName.split('\\n').map((line, i) => (
                                                <span key={i}>{line}<br/></span>
                                            ))}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        ))}
                        <div className="notice">
                            {sets?.filter(s => !!s.notice).map((entry, index) => (
                                <div key={index}><sup>*{noticeIndex2++}</sup> {entry.notice}</div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {matrixConfig?.mode !== 'normal' && (
                <h3 className="pb-1 pb-2 px-1 tertiary">Bitte Auswahl treffen</h3>
            )}
        </>
    );
}

export default SetsView;
