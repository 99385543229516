import {Seat, SeatColor, SeatId, SeatStyle} from '../../types';
import {Point} from '../../geometry';
import {applySeatStyle, createSeatSprite} from '../scene';
import {TagDefinition} from '../../../model/Seating';

export interface SeatSet extends Iterable<Seat> {

    contains(seat: Seat): boolean;

}

export class RenderableSeat implements Seat {

    private _position: Point = {x: 0, y: 0};

    private _color: SeatColor = 0x000000;

    private _style: SeatStyle = 'BLOCKED';

    readonly id: SeatId;

    readonly sprite = createSeatSprite(this._style, this._color);

    tags: string[];

    readonly area?: string;
    readonly row?: string;
    readonly label?: string;

    constructor(seat: Readonly<Seat>) {
        this.id = seat.id;
        this.tags = [...seat.tags];
        this._color = seat.color;
        this._style = seat.style;
        this.position = {x: seat.x, y: seat.y};
        this.area = seat.area;
        this.row = seat.row;
        this.label = seat.label;

        this.redraw();
    }

    get x() {
        return this.position.x;
    }

    get y() {
        return this.position.y;
    }

    get position(): Readonly<Point> {
        return this._position;
    }

    get color(): SeatColor {
        return this._color;
    }

    set color(color: SeatColor) {
        this._color = color;
        this.redraw();
    }

    get style(): SeatStyle {
        return this._style;
    }

    set style(style: SeatStyle) {
        this._style = style;
        this.redraw();
    }

    set position(position: Readonly<Point>) {
        this._position.x = position.x;
        this._position.y = position.y;
        this.sprite.position.copyFrom(this);
    }

    private redraw() {
        applySeatStyle(this.sprite, this.style, this.color);
    }

}

/**
 * Bestimmt die effektiv für einen Seat anzuwendenden tags.
 *
 * @param seatTags Die tags eines Seat, die betrachtet werden sollen.
 * @param tagDefinitions Die TagDefinitionen die zu berücksichtigen sind.
 *
 * @return Eine Liste der effektiv anwendbaren tags eines Seat,
 *  geordnet entsprechend der Reihenfolge der Tag-Definitionen.
 */
export function determineEffectiveTagsForSeat(seatTags: string[], tagDefinitions: TagDefinition[]): string[] {
    const seatTagSet = new Set(seatTags);

    // Um die Reihenfolge der tags entsprechend der Definition zu erhalten,
    // verwenden wir die Definitionen als "führendes Set".
    return tagDefinitions
        .map(t => t.id)
        // Die effektiv anwendbaren Tags ergeben sich aus der Schnittmenge der definierten Tags und der an
        // einem Seat deklarierten tags. Da aber Set() keine der üblichen Mengen-Operationen implementiert,
        // hier manuell durch member-Prüfung abbilden.
        .filter(t => seatTagSet.has(t));
}
