import * as PIXI from 'pixi.js';
import {Seat} from '../types';
import {setupRenderer, setupViewport} from './index';
import {DisplayContext, getIdleState, InteractionMode, InteractionState} from './interaction';
import {debounce} from 'lodash';
import {Scene} from './scene';
import {DataManager} from './data';
import {InteractionCallback} from './interaction/event';
import {RowLabelVisibilityMode} from './labels/rows';
import {Vector} from '../geometry';
import {loadBackdropTexture} from './resource';

const INITIAL_ZOOM_FACTOR = 20;
const LABEL_REFRESH_DEBOUNCE_DELAY = 500;

export class VenuePlanDisplay {
    get interactionMode(): InteractionMode {
        return this._interactionMode;
    }

    set interactionMode(mode: InteractionMode) {
        this._interactionMode = mode;
        this.interactionState.onEviction(this.createContext());
        this.interactionState = getIdleState(this._interactionMode);
    }

    set onInteraction(value: InteractionCallback | undefined) {
        this._onInteraction = value ?? ((event) => {
            // noop by default
        });
    }

    get rowLabelVisibilityMode() {
        return this._rowLabelVisibilityMode;
    }

    set rowLabelVisibilityMode(value: RowLabelVisibilityMode) {
        this._rowLabelVisibilityMode = value;
        this.refreshRowAndSeatLabels();
    }

    get showSeatLabels() {
        return this._showSeatLabels;
    }

    set showSeatLabels(value) {
        this._showSeatLabels = value;
        this.refreshRowAndSeatLabels();
    }

    private readonly canvas: HTMLCanvasElement;

    private readonly scene: Scene;

    private readonly dataManager: DataManager;

    private renderer?: PIXI.Renderer;

    private _interactionMode: InteractionMode = 'SELECT';

    private _onInteraction: InteractionCallback = (event) => {
        // noop by default
    };

    private interactionState: InteractionState

    private _rowLabelVisibilityMode: RowLabelVisibilityMode = 'ALL';

    private _showSeatLabels: boolean = true;

    constructor(canvas: HTMLCanvasElement) {

        this.canvas = canvas;
        this.renderer = setupRenderer(this.canvas);

        const ticker = new PIXI.Ticker();
        ticker.add(() => this.renderer && this.renderer.render(this.scene.root), PIXI.UPDATE_PRIORITY.LOW);
        ticker.start();

        const viewport = setupViewport(
            this.canvas.offsetWidth,
            this.canvas.offsetHeight,
            this.renderer.plugins.interaction
        )

        this.scene = new Scene(viewport);
        this.scene.root.interactive = true;
        this.scene.overlay.root.interactive = true;

        this.dataManager = new DataManager(this.scene, (event) => {
            this._onInteraction(event);
        });

        this.interactionState = getIdleState(this._interactionMode);

        const events = [
            'click',
            'mousedown',
            'mouseout',
            'mouseover',
            'mouseup',
            'mouseupoutside',
            'pointercancel',
            'pointerdown',
            'pointermove',
            'pointerout',
            'pointerover',
            'pointertap',
            'pointerup',
            'pointerupoutside',
            'rightclick',
            'rightdown',
            'rightup',
            'rightupoutside',
            'tap',
            'touchcancel',
            'touchend',
            'touchendoutside',
            'touchmove',
            'touchstart'
        ]

        events.forEach(event => {
            this.scene.root.on(event, this.handleInteractionEvent.bind(this));
        });

        this.setupSceneGraph();
    }

    private createContext(): DisplayContext {
        return {
            interactionMode: this._interactionMode,
            scene: this.scene,
            dataManager: this.dataManager,
            dispatch: this._onInteraction
        }
    }

    private handleInteractionEvent(event: PIXI.InteractionEvent) {
        const globalPoint = event.data.global;
        const localPoint = this.scene.convertToViewportCoords(globalPoint);
        this.interactionState = this.interactionState.onInteraction(this.createContext(), event, localPoint);

        // Da die sprites der Sitzplätze in einem ParticleContainer verwaltet werden kann man keine
        // Interaktions-Events direkt an diese hängen bzw. diese werden nicht berücksichtigt.
        // Dementsprechend funktioniert auch nicht das setzen des "buttonMode", damit sich der Cursor
        // in einen Pointer ändert beim mouseover.
        // Dieses verhalten können wir aber simulieren indem wir explizit versuchen einen Sitzplatz
        // zu picken und wenn das gelingt bedeutet dies, dass sich der Cursor über einem Platz befindet
        // und wir können dann entsprechend explizit den Cursor ändern.
        if (process.env.REACT_APP_ENABLE_SEAT_POINTER === 'true' && event.type === 'pointermove') {
            // Da das picking aktuell noch über brute-force prüfen der Abstände zu allen
            // Sitzplätzen funktioniert, *könnte* es sein, dass dies ein Performanceproblem
            // ist, da in jedem Frame in dem der Cursor bewegt wurde, dazu alle Sitzplätze
            // durchgegangen werden müssen.
            const seat = this.dataManager.pickSeat(localPoint);
            this.scene.root.cursor = (seat && seat.style !== 'BLOCKED') ? 'pointer' : 'auto';
        }
    }

    private setupSceneGraph() {
        // FIXME: Den gizmo togglebar machen
        // const gizmo = new PIXI.Graphics();
        // gizmo.lineStyle(1, 0x00FF00, 1);
        // gizmo.moveTo(0, 0);
        // gizmo.lineTo(10, 0);
        // gizmo.lineStyle(1, 0xFF0000, 1);
        // gizmo.moveTo(0, 0);
        // gizmo.lineTo(0, 10);
        // gizmo.beginFill(0xFFFF00, 1);
        // gizmo.lineStyle(0);
        // gizmo.drawCircle(0, 0, 1);
        // this.scene.viewport.root.addChild(gizmo);

        this.scene.viewport.root.setZoom(INITIAL_ZOOM_FACTOR);
    }

    /**
     * Fügt die spezifizierten Sitzplätze zum Saalplan hinzu.
     * @param seats
     */
    addSeats(seats: Seat[]): void {
        this.dataManager.addSeats(seats);
        this.refreshRowAndSeatLabels();
    }

    /**
     * Entfernt alle Sitzplätze aus der Anzeige.
     */
    clearSeats(): void {
        this.dataManager.clearSeats();
        this.refreshRowAndSeatLabels();
    }

    /**
     * Die Anzeige der Reihen-Labels aktualisieren.
     *
     * Diese Funktion ist debounced, damit der recht teure Prozess nicht unnötig oft aufgerufen wird.
     */
    private refreshRowAndSeatLabels = debounce(() => {
        this.dataManager.refreshRowAndSeatLabels(this._rowLabelVisibilityMode, this._showSeatLabels);
    }, LABEL_REFRESH_DEBOUNCE_DELAY, {
        // Durch zusätzliches triggern auf der leading edge, wird der refresh min. einmal
        // direkt beim ersten Aufruf durchgeführt so dass man bei einer Einzelaktion
        // direkt eine Änderung sieht. Die nächsten refreshes erfolgen dann debounced.
        leading: true,
        trailing: true
    });

    /**
     * Passt die Ansicht an, so dass die gesamte Szene in den Viewport passt.
     */
    fitViewport() {
        this.scene.fitViewport();
    }

    /**
     * Setzt ein Bild welches unter der angegebenen URL abgerufen werden kann als Hintergrundbild.
     *
     * @param url Die URL des Hintergrundbildes
     * @param scale Skalierungsfaktor mit dem das Hintergrundbild angezeigt werden soll.
     * @param offset Offset mit dem das Hintergrundbild angezeigt werden soll.
     */
    async setBackdropImage(url: string, scale = 1.0, offset: Vector = {x: 0, y: 0}): Promise<void> {
        this.scene.setBackdropImage(await loadBackdropTexture(url), scale, offset);
    }

    /**
     * Entfernt das aktuelle Hintergrundbild der Szene.
     */
    unsetBackdropImage(): void {
        this.scene.unsetBackdropImage();
    }

}

export default VenuePlanDisplay
