import {RenderableSeat, SeatSet} from './common';
import Flatten from '@flatten-js/core';
import {Seat} from '../../types';
import {calculateBoundingBox, difference, Point, sum} from '../../geometry';
import {Marquee, Scene, SEAT_RADIUS} from '../scene';
import {Container, Rectangle} from 'pixi.js';
import {DispatchInteractionEvent} from '../interaction/event';

const SELECTION_MARQUEE_COLOR = 0xFF0000;

export interface Selection extends SeatSet {

    readonly id: string;

    readonly boundingBox: Flatten.Box;

    /**
     * Verschiebt die Auswahl an einen bestimmten Punkt.
     *
     * Der Referenzpunkt auf den sich die Verschiebung bezieht ist der Mittelpunkt der bounding box der Auswahl.
     *
     * @param to Der Punkt zu dem die Auswahl verschoben werden soll.
     */
    move(to: Point): void;

    /**
     * Wendet evtl. Änderungen am transform der Auswahl durch move() etc. an,
     * d.h. die Änderungen werden auch tatsächlich auf die in der Auswahl
     * enthaltenen Seats angewendet. Vorher sind alle Änderungen nur
     * transienter Natur rein zur Anzeige.
     */
    commitTransform(): void;

}

export class CurrentSelection implements Selection {

    private readonly marquee: Marquee;

    private readonly container: Container = new Container();

    constructor(
        public readonly id: string,
        public readonly seats: RenderableSeat[],
        private readonly scene: Scene,
        private readonly dispatch: DispatchInteractionEvent
    ) {
        // Für die Dauer der Auswahl werden die Seats in einen eigenen Container verschoben,
        // damit man diese "am Stück" transformieren kann ohne die Position jedes Seat
        // einzeln zu modifizieren.
        this.scene.viewport.seats.addChild(this.container);
        this.seats.forEach(s => {
            this.container.addChild(s.sprite);
        })

        const isMultiSeatSelection = this.seats.length > 1;
        this.marquee = this.scene.addMarquee(this.id, this.getMarqueeRect(), SELECTION_MARQUEE_COLOR, isMultiSeatSelection);

        this.dispatch({type: 'SEATS_SELECTED', seats: Array.from(this)});
    }

    [Symbol.iterator](): Iterator<Seat> {
        return this.seats[Symbol.iterator]();
    };

    get boundingBox(): Flatten.Box {
        return calculateBoundingBox(this.seats)
    }

    private getMarqueeRect(): Rectangle {
        const bb = this.boundingBox;
        const offset = this.container.position;
        const rect = new Rectangle(bb.xmin + offset.x, bb.ymin + offset.y, bb.xmax - bb.xmin, bb.ymax - bb.ymin);
        return rect.pad(SEAT_RADIUS);
    }

    move(to: Point): void {
        const bb = this.boundingBox;


        this.container.position.copyFrom(difference(to, bb.center));

        this.marquee.box = this.getMarqueeRect();
    }

    contains(seat: Seat): boolean {
        // return includes(this.seats, seat);
        return this.seats.some(e => e.id === seat.id);
    }

    commitTransform(): void {
        this.seats.forEach(s => {
            s.position = sum(this.container.position, s.position);
        })
        this.container.position.set(0, 0);
        this.marquee.box = this.getMarqueeRect();

        this.dispatch({type: 'SEATS_UPDATED', seats: Array.from(this)});
    }


    private _clear(reinsertSeatsIntoScene: boolean = true): void {
        this.scene.removeMarquee(this.marquee);
        this.scene.viewport.seats.removeChild(this.container);

        if (reinsertSeatsIntoScene) {
            // Seats wieder direkt in die Szene einfügen.
            this.seats.forEach(s => {
                this.scene.viewport.seats.addChild(s.sprite);
            })
        }

        this.dispatch({type: 'SEATS_UNSELECTED'})
    }

    /**
     * Hebt diese Auswahl auf.
     */
    clear(): void {
        this._clear();
    }

    /**
     * Löscht alle Sitzplätze in dieser Auswahl aus dem Saalplan und hebt die Auswahl auf.
     */
    deleteSeats(): void {
        this.dispatch({type: 'SEATS_DELETED', seats: Array.from(this)});
        this._clear(false);
    }

}
