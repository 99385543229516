import {getLogger, levels} from 'loglevel';
import {throttle} from 'lodash';

export const log = getLogger('venuePlan');

log.setDefaultLevel(process.env.NODE_ENV === 'development' ? levels.DEBUG : levels.INFO);

export const logThrottled = throttle((message: string): void => {
    log.debug(message);
}, 100);


