import {TimeSlot} from '../model/timeslot';
import React, {ChangeEvent, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useIsBackendCall} from '../page/SeatingShopPage';
import {useRecoilValue} from 'recoil';
import {timeSlotSelectionCountState} from '../state';
import {range, sampleSize} from 'lodash';
import log from 'loglevel';

// TODO: evtl. konfigurierbar machen?
const HOM_SLOT_AMOUNT_LIMIT = 10;

export type TimeSlotSelectProps = {
    timeSlot: TimeSlot;
    // TODO: Diesen callback per context bereitstellen, anstatt nach unten durchzufädeln.
    toggleSeats: (publicIds: string[]) => void;
};

/**
 * Select für eine Anzahl von Tickets für einen Zeitslot.
 *
 * @param timeSlot Der TimeSlot für den Tickets ausgewählt werden sollen.
 * @param toggleSeats
 */
export const TimeSlotSelect: React.FC<TimeSlotSelectProps> = ({timeSlot, toggleSeats}) => {
    const intl = useIntl();
    const isBackendCall = useIsBackendCall();
    const timeSlotSelectionCount = useRecoilValue(timeSlotSelectionCountState);

    const label = useMemo(() => {
        const start = intl.formatTime(timeSlot.start);
        const end = intl.formatTime(timeSlot.end);
        return `Einlass: ${start} - ${end}`;
    }, [timeSlot, intl])

    const selectSeatsForSlot = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === '') {
            return;
        }

        const amount = Number(event.target.value);

        // <select> zurücksetzen auf "Bitte wählen"
        event.target.value = '';

        // Da die Zeitslot-Auswahl nur die Sitzplatzauswahl "in Verkleidung" ist, werden nicht etwa fungible Zeitslots
        // ausgewählt, sondern konkrete Sitzplätze, so dass es zu Konflikten kommt, wenn mehrere clients versuchen
        // würden basierend auf den gleichen Verfügbarkeitsinformation die "ersten x Plätze in einem Zeitslot" zu
        // buchen, da dies eine Auswahl der selben Plätze bedeuten würde.
        // Um dieses Problem etwas abzumildern, daher hier ein random sample der verfügbaren Plätze auswählen,
        // so dass die Wahrscheinlichkeit des Auftretens von Konflikten zu verringert wird.
        const idsToSelect = sampleSize(timeSlot.availableIds, amount);

        log.debug(`select ${idsToSelect.length} seats for time slot "${timeSlot.id}":`, idsToSelect);

        toggleSeats(idsToSelect);
    }, [timeSlot, toggleSeats])

    const limit = Math.max(0, HOM_SLOT_AMOUNT_LIMIT - (timeSlotSelectionCount[timeSlot.id] ?? 0))
    // Wenn es ein "backend" Kauf ist (Aufruf über "geheime" URL), dann gibt es keine Obergrenze für die Menge der auswählbaren Tickets
    const maxAmount = isBackendCall ? timeSlot.availableIds.length : Math.min(limit, timeSlot.availableIds.length);

    // Auswahl soll nicht mehr angezeigt werden, wenn keine slots auswählbar
    if (maxAmount < 1) {
        return null;
    }

    return (
        <div key={timeSlot.id} className="m-0 p-0 mb-2 mx-0 me-1 pb-1 select-node tertiary col">
            <div className="row">
                <div>{label}</div>
                <div>
                    <select onChange={selectSeatsForSlot}>
                        <option value="">Bitte wählen</option>
                        {range(maxAmount).map(i => (
                            <option key={i} value={i + 1}>{i + 1} Tickets</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
};

export default TimeSlotSelect;
