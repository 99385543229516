import React from 'react';
import {SeatSelectionTextualView} from './SeatSelectionTextualView';
import {useQueryParams} from '../routing/hooks';
import VenuePlan from '../venuePlan/VenuePlan';
import {FALLBACK_QUERY_PARAM} from "../api/queries";

export type SeatSelectionProps = {
    toggleSeats: (publicIds: string[]) => void;
};

/**
 * Unnötiger Wrapper für den Saalplan um zwischen dem eigentlichen Saalplan oder der Debug-Textdarstellung wechseln zu
 * können...
 * FIXME die Textdarstellung wegwerfen und diese Ebene entfernen und VenuePlan nach oben ziehen
 *
 * @param toggleSeat
 * @constructor
 */
const SeatSelection: React.FC<SeatSelectionProps> = ({toggleSeats}: SeatSelectionProps) => {
    const queryParams = useQueryParams();
    const showTextualFallbackForSelection = queryParams[FALLBACK_QUERY_PARAM] !== undefined;

    if (showTextualFallbackForSelection) {
        return <SeatSelectionTextualView toggleSeats={toggleSeats}/>;
    }

    return <VenuePlan onSeatPicked={s => toggleSeats([s.id])}/>
};

export default SeatSelection;
