import React, {useEffect, useState} from 'react';
import './PytSelection.scss';
import {useRecoilState} from 'recoil';
import {selectedSetFilterState} from '../state';
import {generateSelectorTree, PytDatesMapperConfig, SelectorTreeNode} from '../model/PytExtensions';
import PytSeatSelection from './PytSeatSelection';
import ScrollContainer from './ScrollContainer';
import {useAvailableTimeslotsDatesQuery} from '../api/queries';

export type PytSelectionProps = {
    toggleSeats: (publicIds: string[]) => void;
};

type DateStringSelectorTreeNode = SelectorTreeNode<string>;

export const PytDateSelection: React.FC<PytSelectionProps> = (props: PytSelectionProps) => {
    const [selectedSetFilter, setSelectedSetFilter] = useRecoilState(selectedSetFilterState);
    const {data: availableTimeslotsDates} = useAvailableTimeslotsDatesQuery();
    const [selectedTree, setSelectedTree] = useState<DateStringSelectorTreeNode[]>([]);

    const tree: DateStringSelectorTreeNode = {
        value: 'root',
        level: 1,
        children: []
    };

    useEffect(() => {
        if (availableTimeslotsDates) {
            generateSelectorTree(availableTimeslotsDates, new PytDatesMapperConfig('de'), tree);
            setSelectedTree([tree]);
        }
        // FIXME Erwartete Abhängigkeit zu pytDates und tree nicht gewollt
        // FIXME: ^^^ ???? ^^^, evtl. wegen infinite loop Gefahr da hier tree direkt manipuliert wird?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTimeslotsDates]);

    useEffect(() => {
        if (selectedTree.length !== 1) {
            return;
        }

        // auto auswahl wenn nur ein child, pro level
        let currentNode = selectedTree[0];
        let level = 1;

        while (currentNode.children.length === 1) {
            setTreeSelection(currentNode.children[0], level++);
            currentNode = currentNode.children[0];
        }

        // FIXME Erwartete Abhängigkeit zu setTreeSelection nicht gewollt
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTree]);

    const setTreeSelection = (node: DateStringSelectorTreeNode, level: number): void => {
        if (node.mapperPayload) {
            setSelectedSetFilter(node.mapperPayload);
        } else {
            setSelectedSetFilter('disabled');
        }

        const selection = selectedTree;
        selection[level] = node;

        while (level < selection.length - 1) {
            selection.pop();
        }

        setSelectedTree([...selection]);
    }

    return (
        <>
            {selectedTree.filter((node) => node.children.length).map((parentNode, index) => (
                <div key={index} className="container px-2 g-2 m-0">
                    <ScrollContainer id={String(index)} nodes={parentNode.children.map((node, _index) => (
                        <div key={`node-${index + _index}-${node.level}`}
                             className={`click-node click-node-level${index + 1} ${selectedTree[index + 1] === node ? 'active' : ''}`}
                             onClick={() => setTreeSelection(node, index + 1)}>{node.value}</div>
                    ))}/>
                </div>
            ))}

            {selectedTree.filter((node) => node.level > 1).length > 0 && (
                <div className="container p-0 mx-0 mt-4 mb-2">
                    <h2>Deine Auswahl</h2>
                    <div className="row mx-0">
                        {selectedTree.filter((node) => node.level > 1).map((node, index, parent) => (
                            <div key={'selection' + index}
                                 className={`mx-1 mb-1 col selection-node ${!index ? 'ms-0' : ''} ${(index === parent.length - 1) ? 'me-0' : ''}`}>{node.value}</div>
                        ))}
                    </div>
                </div>
            )}

            {(selectedSetFilter && selectedSetFilter !== 'disabled') && (
                <div className="container p-0 mx-0 mt-4 mb-2">
                    <h2>Verfügbare Zeiten</h2>
                    <PytSeatSelection toggleSeats={props.toggleSeats}/>
                </div>
            )}
        </>
    );
}

export default PytDateSelection;
