import React from 'react';
import './VoucherView.scss';
import {useRecoilValue} from 'recoil';
import {matrixConfigState} from '../state';

type VoucherViewProps = {
    submitCode: (event: any) => void;
};

const VoucherView = (props: VoucherViewProps): React.ReactElement => {
    const matrixConfig = useRecoilValue(matrixConfigState);

    return (
        <div id="voucher" className="container mb-4 clearfix">
            {matrixConfig?.mode === 'normal' && (<h3>Sie haben einen Code?</h3>)}
            {matrixConfig?.mode !== 'normal' && (<h3>Du hast einen Promotioncode?</h3>)}

            <form onSubmit={props.submitCode}>
                <div className="d-flex m-0 justify-content-center float-lg-start w-100" id="voucher-form">
                    <input type="text" className="form-control w-100" name="code" id="voucher-input"
                           placeholder="Bitte Code eingeben"/>
                    <div className="p-0 m-0">
                        <button type="submit" className="voucher-button btn btn-primary h-100 m-0">
                            &gt;
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default VoucherView;
