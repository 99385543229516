import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {levels, setDefaultLevel} from 'loglevel';
import {RecoilRoot} from 'recoil';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import {loadResources} from './venuePlan/display/resource';

setDefaultLevel(process.env.NODE_ENV === 'development' ? levels.DEBUG : levels.INFO);

// TODO: passende configuation für react-query
const enableReactQueryDevTools = process.env.REACT_APP_ENABLE_REACT_QUERY_DEV_TOOLS === 'true';
const queryClient = new QueryClient();

// Sicherstellen, dass die Resourcen für das Rendering verfügbar sind.
loadResources().then(() => {
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <App/>
                </RecoilRoot>
                {/*
                    TODO: Auch wenn sie nicht aktiv sind, würden sie im PROD system included werden,
                        daher evtl. per NODE_ENV guard "tree-shaken" lassen?
                */}
                {enableReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false}/>}
            </QueryClientProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
