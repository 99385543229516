import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SeatingShopPage from './page/SeatingShopPage';
import AlertsView from './component/AlertsView';
import api from './api';
import {AxiosResponse} from 'axios';
import TimeoutModalView from './component/TimeoutModalView';
import {ErrorWithKey} from './common/types';
import {uniqueId} from 'lodash';

const App = (): React.ReactElement => {
    const [errors, setErrors] = useState<ErrorWithKey[]>([]);
    const [isExclusiveError, setIsExclusiveError] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const createError = (error: React.ReactNode): ErrorWithKey => ({key: uniqueId('error'), error});

    useEffect(() => {
        // Das hier muss unbedingt innerhalb eines nur beim initialen ausgeführten render ausgeführten useEffect()
        // geschehen, sonst werden bei jedem render zusätzliche request/response interceptors registriert und jeder
        // dieser verursacht durch Setzen des state, das weitere renders ausgelöst werden, so dass über die Zeit die
        // Anzahl der registrierten interceptors und dadurch verursachte re-renders exponentiell ansteigt.
        // FIXME: Idealerweise würde globale Fehlermeldungen anders implementiert sein,
        //      als sich hier direkt in den API client reinzuhängen.
        api.configure(
            (isLoading: boolean, url?: string | undefined) => {
                if (url === undefined || url?.indexOf('seat-views') === -1) {
                    setLoadingState(isLoading);
                }
            },
            (response: AxiosResponse) => {
                if (response.data.message !== undefined) {
                    setSuccessMessage(response.data.message);
                }
            }, (response: AxiosResponse) => {
                if (response?.status === undefined) {
                    return;
                }
                if (response.status === 404) {
                    setErrors(prevErrors => [...prevErrors, createError(<>
                        <strong>Wartungsarbeiten</strong><br/><br/> Wir arbeiten gerade am Ticketshop.
                        Dieser ist in Kürze wieder erreichbar. Vielen Dank für die Geduld!</>)]);
                    setIsExclusiveError(true);
                } else if (response.status === 409) {
                    setErrors(prevErrors => [...prevErrors, createError(<>{response.data.error}</>)]);
                } else if (response.status === 500) {
                    setErrors(prevErrors => [...prevErrors, createError(<>{response.data.message}</>)]);
                }
            });
    }, []);

    return (
        <Router>
            <div className="app">
                <AlertsView loadingState={loadingState} successMessage={successMessage}
                            setSuccessMessage={setSuccessMessage}
                            errors={errors} setErrors={setErrors}/>
                <Switch>
                    {!isExclusiveError && (
                        <Route exact path="/:slug/seating-shop" component={SeatingShopPage}>
                        </Route>
                    )}
                </Switch>
                <TimeoutModalView/>

            </div>
        </Router>
    );
}

export default App;
