import './TimeoutModalView.scss';
import React, {useEffect} from "react";
import "./AlertsView.scss";
import {useRecoilState} from "recoil";
import {countdownState} from "../state";


const TimeoutModalView = (): React.ReactElement => {
    const [countdown, setCountdown] = useRecoilState(countdownState);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown) {
                setCountdown(countdown - 1);
            }
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);

    return (<>
            {countdown === 0 && <>
                <div id="modal-countdown" className="fixed-top d-grid">
                    <div>
                        <p>Die Reservierungszeit ist leider abgelaufen. Bitte treffen Sie Ihre Auswahl erneut.</p>
                        <button type="reset" className="btn bg-secondary-custom"
                                onClick={() => window.location.reload()}>Neue Auswahl
                        </button>

                    </div>
                </div>
            </>
            }
        </>
    );
}

export default TimeoutModalView;
