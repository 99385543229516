import Flatten from '@flatten-js/core';
import {maxBy, minBy} from 'lodash';
import {Point, Vector} from './types';

export * from './types';

/**
 * Berechnete die Länge eines Vektors.
 */
export function length(v: Readonly<Vector>): number {
    return Math.sqrt(v.x * v.x + v.y * v.y);
}

/**
 * Calculate the difference a - b of two vectors.
 *
 * @param a
 * @param b
 */
export function difference(a: Readonly<Vector>, b: Readonly<Vector>): Vector {
    return {x: a.x - b.x, y: a.y - b.y};
}

/**
 * Calculate the sum a + b of two vectors.
 *
 * @param a
 * @param b
 */
export function sum(a: Readonly<Vector>, b: Readonly<Vector>): Vector {
    return {x: a.x + b.x, y: a.y + b.y};
}

/**
 * Scales a given vector by a scalar value.
 *
 * @param v The vector to be scaled.
 * @param s The scalar value to scale the vector by
 */
export function scale(v: Readonly<Vector>, s: number): Vector {
    return {x: v.x * s, y: v.y * s};
}

/**
 * Calculate the distance between two points.
 *
 * @param a
 * @param b
 */
export function distance(a: Readonly<Point>, b: Readonly<Point>): number {
    return Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
}

/**
 * For a given point returns a new point with the coordinates of the original point rounded to the nearest integer values.
 */
export function toIntegerGrid(p: Readonly<Point>): Point {
    return {x: Math.round(p.x), y: Math.round(p.y)};
}

/**
 * Calculate the bounding box for an array of points.
 *
 * @param points The array of points to calculate the bounding box for.
 *
 * @returns {Box} The bounding box of the array of points.
 */
export function calculateBoundingBox(points: Point[]): Flatten.Box {
    const xMin = minBy(points, 'x')?.x;
    const yMin = minBy(points, 'y')?.y;
    const xMax = maxBy(points, 'x')?.x;
    const yMax = maxBy(points, 'y')?.y;

    return new Flatten.Box(xMin, yMin, xMax, yMax);
}


