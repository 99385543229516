import {InteractionEvent} from 'pixi.js';
import {DisplayContext, InteractionState} from './common';
import {Point} from '../../geometry';

export class PickSeatState implements InteractionState {

    onEviction(context: DisplayContext): void {
        // noop
    }

    onInteraction(context: DisplayContext, event: InteractionEvent, worldPoint: Point): InteractionState {
        // Wir reagieren explizit nur auf das 'pointertap' event und nicht etwa auch auf das 'tap' event,
        // da im Falle eines Touch-Interfaces beide events gefeuert werden und wir somit inkorrekterweise
        // das SEAT_PICKED event doppelt auslösen würden.
        if (event.type === 'pointertap') {
            const seat = context.dataManager.pickSeat(worldPoint);

            if (seat) {
                context.dispatch({type: 'SEAT_PICKED', seat})
            }
        }

        return this;
    }

}
