import React, {useState} from 'react';
import './CartView.scss';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import {matrixConfigState, visibleCartItemsState} from '../state';
import {useRecoilValue} from 'recoil';
import {useQueryParams} from '../routing/hooks';
import {useIsBackendCall} from '../page/SeatingShopPage';
import {FALLBACK_QUERY_PARAM} from '../api/queries';
import {ItemForSeat} from '../model/Seating';
import CartItem from './CartItem';
import {Form} from 'react-bootstrap';
import {isEmpty} from 'lodash';

type CartViewProps = {
    removeCartEntry: (item: ItemForSeat) => void;
    submitCart: (event: React.SyntheticEvent) => void;
    clearSession: () => void;
    updateSeatSelectionForItemForSeat: (itemForSeat: ItemForSeat) => void;
};

/**
 * Komponente, welchen den Warenkorb abbildet.
 *
 * @param removeCartEntry - Callback zum entfernen einer Warenkorbposition
 * @param submitCart - Callback zum submitten des Warenkorbs
 * @param clearSession
 * @param updateSeatSelectionForItemForSeat - Callback zum updaten einer Warenkorbposition
 * @constructor
 */
const CartView: React.FC<CartViewProps> = ({
                                               removeCartEntry,
                                               submitCart,
                                               clearSession,
                                               updateSeatSelectionForItemForSeat
                                           }) => {
    const items: ItemForSeat[] = useRecoilValue(visibleCartItemsState);
    const showResetButton = useQueryParams()[FALLBACK_QUERY_PARAM] !== undefined;
    const isBackendCall: boolean = useIsBackendCall();
    const matrixConfig = useRecoilValue(matrixConfigState);
    const personalizationEnabled = matrixConfig?.personalizationEnabled;

    // https://react-bootstrap.netlify.app/docs/forms/validation
    const [validated, setValidated] = useState(false);

    /**
     * Prüft, ob die minimal geforderte und maximal erlaubte Anzahl zu buchender Sitze eingehalten wird.
     * Wird ignoriert, wenn aus dem Backend aufgerufen und ein Sitz ausgewählt wurde.
     */
    const meetsMinMaxQuantity = (): boolean => {
        const meetsMinQuantity = !matrixConfig?.minQuantity || matrixConfig.minQuantity <= items.length;
        const meetsMaxQuantity = !matrixConfig?.maxQuantity || matrixConfig.maxQuantity >= items.length;
        const notEmpty = !!items?.length;

        return (meetsMinQuantity && meetsMaxQuantity) || (isBackendCall && notEmpty);
    }

    /**
     * Custom onSubmit-Handler für ein Formular mit eigener Validierung.
     * Siehe https://react-bootstrap.netlify.app/docs/forms/validation
     *
     * FIXME Typing
     *
     * @param event
     */
    const onSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        submitCart(event);
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <h3>Auswahl</h3>
            <div id="cart" className="container">
                {isEmpty(items) && (<p className="m-2">Der Warenkorb ist leer</p>)}

                {items.map((itemForSeat, index) =>
                    <CartItem itemForSeat={itemForSeat}
                              index={index}
                              personalizationEnabled={personalizationEnabled ?? false}
                              removeCartEntry={removeCartEntry}
                              updateSeatSelectionForItemForSeat={updateSeatSelectionForItemForSeat}
                              key={`itemForSeat-${index}`}
                    />
                )}

                <div className="cart-buttons-row position-sticky bottom-0 justify-content-between p-2 w-100 mt-4">
                    {showResetButton && (
                        <button type="reset" className="btn bg-secondary-custom" onClick={() => clearSession()}>
                            Reset
                        </button>
                    )}
                    <div className="cart-buttons-hint text-danger m-2">
                        {!meetsMinMaxQuantity() && <>Auswahl nicht möglich</>}
                    </div>

                    <button className="btn btn-primary" type="submit" disabled={!meetsMinMaxQuantity()}>
                        Bestellen
                    </button>
                </div>
            </div>
        </Form>
    );
}

export default CartView;
