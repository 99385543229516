import {RenderableSeat} from './common';
import {Seat} from '../../types';
import {CurrentSelection, Selection} from './selection';
import {Scene, SEAT_RADIUS} from '../scene';
import ObjectID from 'bson-objectid';
import {compact, each, filter, values} from 'lodash';
import Flatten from '@flatten-js/core';
import {DispatchInteractionEvent} from '../interaction/event';
import {distance, Point} from '../../geometry';
import {RowLabelVisibilityMode} from '../labels/rows';

export class DataManager {

    private seats: Record<string, RenderableSeat> = {};

    private currentSelection?: CurrentSelection;

    constructor(private readonly scene: Scene, private readonly dispatch: DispatchInteractionEvent) {
    }

    addSeats(seats: Iterable<Seat>): void {
        for (const s of seats) {
            const seat = new RenderableSeat(s);
            this.seats[seat.id] = seat;
            this.scene.viewport.seats.addChild(seat.sprite);
        }
    }

    clearSeats(): void {
        this.currentSelection?.clear();
        this.currentSelection = undefined;
        this.scene.viewport.seats.removeChildren();
        this.seats = {};
    }

    pickSeat(at: Point): Readonly<Seat> | undefined {
        let minDistance = Number.MAX_VALUE;
        let closestSeat = undefined;

        each(this.seats, seat => {
            const d = distance(at, seat);

            if (d < minDistance) {
                minDistance = d;
                closestSeat = seat;
            }
        })

        if (closestSeat && minDistance < SEAT_RADIUS) {
            return closestSeat;
        }

        return undefined;
    }

    selectSeats(seats: Seat[]): Selection {
        const selectedSeats = compact(seats.map(s => this.seats[s.id]));

        if (!selectedSeats.length) {
            throw new Error('you need to select at least one seat');
        }

        this.currentSelection?.clear();

        this.currentSelection = new CurrentSelection(
            (new ObjectID()).toHexString(),
            selectedSeats,
            this.scene,
            this.dispatch
        );

        return this.currentSelection;
    }

    /**
     * Erzeugt eine Auswahl über alle Sitzplätze, die sich in einem Auswahlbereich befinden.
     *
     * @param box Der Auswahlbereich
     *
     * @return Die Auswahl über alle Sitzplätze im Auswahlbereich, oder undefined,
     *         wenn sich keine Sitzplätze im Auswahlbereich befinden.
     */
    selectSeatsByMarquee(box: Flatten.Box): Selection | undefined {
        // FIXME: Die brute-force Suche hier funktioniert ist aber ist bei größeren Mengen evtl. nicht performant genug.
        // Bei Performanceproblemen ggf. geeignete Datenstrukturen wie Spatial-Hash, Interval-Tree, etc... einsetzen.
        const seats = filter(this.seats, seat => {
            return box.xmin <= seat.x
                && box.xmax >= seat.x
                && box.ymin <= seat.y
                && box.ymax >= seat.y
        });

        if (seats.length) {
            return this.selectSeats(seats);
        }
    }

    clearCurrentSelection(): void {
        this.currentSelection?.clear();
        this.currentSelection = undefined;
    }

    /**
     * Die Reihen- und Seat-Labels aktualisieren.
     */
    refreshRowAndSeatLabels(rowLabelVisibilityMode: RowLabelVisibilityMode, showSeatLabels: boolean): void {
        const seats = values(this.seats);
        this.scene.updateRowLabels(seats, rowLabelVisibilityMode);
        this.scene.updateSeatLabels(seats, showSeatLabels);
    }
}
