import React, {useEffect, useMemo, useRef} from 'react';
import styles from './VenuePlan.module.scss';
import VenuePlanDisplay from './display/VenuePlanDisplay';
import {InteractionCallback} from './display/interaction/event';
import {useRecoilState, useRecoilValue} from 'recoil';
import {displayedCartItemsState, selectedSetIdState, selectedSetState} from '../state';
import {useSeatDataQuery, useTagDefinitionsQuery} from '../api/queries';
import {ItemForSeat, Seat as SeatData, SeatStatus, TagDefinition} from '../model/Seating';
import {Seat, SeatColor, SeatStyle} from './types';
import {loadResources} from './display/resource';
import {SEAT_BLOCKED_COLOR, SEAT_DEFAULT_COLOR} from './display/scene';
import {determineEffectiveTagsForSeat} from './display/data/common';
import log from 'loglevel';

/**
 * Gibt für einen SeatStatus den Style zurück.
 *
 * FIXME ein Grund mehr die Status der Plätze zu uppercasen, damit hier nicht so n Zirkus gemacht werden muss...
 *
 * @param status
 */
function mapSeatStatusToStyle(status: SeatStatus): SeatStyle {
    switch (status) {
        case 'available':
            return 'AVAILABLE';
        case 'selected':
            return 'SELECTED';
        default:
            return 'BLOCKED';
    }
}

/**
 * Wandelt einen Hexadezimal-String in einen Integer-Wert um, der als Farbwert verwendet werden kann.
 *
 * @param color Der zu parsende string.
 *
 * @return Der durch den color-string repräsentierte Integer-Wert, oder SEAT_DEFAULT_COLOR,
 *  wenn der string nicht als Hexadezimal-Wert geparsed werden kann.
 */
export function parseSeatColor(color: string): SeatColor {
    return parseInt(color, 16) || SEAT_DEFAULT_COLOR;
}

type VenuePlanProps = {
    onSeatPicked?: (seat: Seat) => void;
}

/**
 * Bestimmte die für einen Platz anzuzeigenden Farbe in Abhängigkeit der zugewiesenen tags.
 *
 * @param seatTags Die zu berücksichtigenden tag.
 * @param tagDefinitions Die zu berücksichtigenden Tag-Definitionen.
 */
export function determineSeatColor(seatTags: string[], tagDefinitions: TagDefinition[]): SeatColor {
    const effectiveTags = determineEffectiveTagsForSeat(seatTags, tagDefinitions);
    const primaryTag = (tagDefinitions ?? []).find(t => t.id === effectiveTags[0]);
    return parseSeatColor(primaryTag?.color ?? SEAT_DEFAULT_COLOR.toString(16));
}

const VenuePlan: React.FC<VenuePlanProps> = ({onSeatPicked}: VenuePlanProps) => {
    const selectedSetId = useRecoilValue(selectedSetIdState);
    const [selectedSet, setSelectedSet] = useRecoilState(selectedSetState);
    const displayedCartItems = useRecoilValue(displayedCartItemsState);

    // FIXME: react-query prüft selbständig per deep-comparison ob sich die Ergebnisse der query verändert haben,
    //  so dass der naive Ansatz immer alles zu fetchen und alles neu zu zeichnen, generell funktioniert.
    //  Dies ist aber nicht unbedingt die effizienteste Implementierung.
    const {data: seatData, isFetched: isSeatDataFetched, error: seatDataError} = useSeatDataQuery(selectedSetId);
    const {data: tagDefinitions} = useTagDefinitionsQuery(selectedSetId);

    const canvas = useRef<HTMLCanvasElement>(null);
    const display = useRef<VenuePlanDisplay>();

    const mapSeatDataForDisplay = useMemo(() => {

        const itemsById: Record<string, ItemForSeat> = {};
        displayedCartItems.forEach(i => itemsById[i.publicId] = i);

        log.debug('mapSeatDataForDisplay', {...itemsById});

        const getDisplayStatus = (seat: SeatData): SeatStatus => {
            const item = itemsById[seat.publicId];

            if (!item) {
                return seat.status;
            }

            switch (item.status) {
                case 'PENDING':
                    return item.removed ? seat.status : 'selected';
                case 'SELECTED':
                    return item.removed ? 'available' : 'selected';
            }
        };

        return (seat: SeatData): Seat => {
            const status = getDisplayStatus(seat);

            // Wenn der Platz nicht verfügbar ist immer "ausgrauen", denn sonst kann man nicht
            // unterscheiden, ob der Platz besetzt ist oder durch einen selbst ausgewählt ist.
            const color = status === 'not_available'
                ? SEAT_BLOCKED_COLOR
                : determineSeatColor(seat.tags, tagDefinitions ?? []);

            return {
                id: seat.publicId,
                tags: seat.tags,
                area: seat.area,
                row: seat.row,
                label: seat.label,
                style: mapSeatStatusToStyle(status),
                color,
                x: seat.x,
                y: seat.y,
            };
        };
    }, [tagDefinitions, displayedCartItems])

    const pickSeat = (seat: Seat): void => {
        if (seat.style !== 'BLOCKED') {
            // Die Unterscheidung, ob ein Platz aus-/abgewählt werden soll wird an anderer
            // Stelle entschieden, daher nur signalisieren, dass der Platz geklickt wurde.
            onSeatPicked?.(seat);
        }
    }

    // da zu überempfindlich, für diesen bereich warning deaktiviert
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInteractionEvent: InteractionCallback = (event) => {
        switch (event.type) {
            case 'SEATS_ADDED':
            case 'SEATS_UPDATED':
                return; // TODO
            case 'SEATS_DELETED':
                return; // TODO
            case 'SEATS_SELECTED':
                return; // TODO
            case 'SEATS_UNSELECTED':
                return; // TODO
            case 'SEAT_PICKED':
                return pickSeat(event.seat);
        }
    }

    // Gibt es eine Fehler-Response, dann das set zurücksetzen, um "festfressen" zu vermeiden.
    useEffect(() => {
        if (seatDataError) {
            setSelectedSet(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seatDataError]);

    // Anzeige initialisieren
    useEffect(() => {
        async function initDisplay() {
            await loadResources();
            // canvas ref should always be defined when this effect is run as the component was already mounted in the DOM
            display.current = new VenuePlanDisplay(canvas.current!);
            display.current.interactionMode = 'PICK';
            display.current.rowLabelVisibilityMode = selectedSet?.displayConfig.rowLabelVisibilityMode ?? 'ALL';
            display.current.showSeatLabels = selectedSet?.displayConfig.showSeatLabels ?? true;
        }

        initDisplay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Der callback muss stets neu registriert werden, wenn sich die props o.Ä. ändern,
    // denn da er als closure erzeugt wird würden andernfalls die ganzen ursprünglichen
    // Werte auf ewig weiterverwendet werden, da der callback außerhalb der React-Welt
    // aufgerufen wird, welche eben nicht an dem ganze rerendering on-change teilnimmt.
    useEffect(() => {
        if (display.current) {
            display.current.onInteraction = handleInteractionEvent;
        }
        // da zu überempfindlich, für diesen bereich warning deaktiviert
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleInteractionEvent])

    // Bei Änderungen des ausgewählten Set müssen andere Sitzplatzdaten angezeigt werden.
    useEffect(() => {
        // bei jeder Änderung die aktuelle Anzeige zunächst mal leeren
        display.current?.clearSeats();

        // ggf. sind die Sitzplatzdaten noch nicht geladen, dann aussteigen und auf den nächsten cycle warten.
        if (!seatData) {
            return;
        }

        if (display.current) {
            display.current.rowLabelVisibilityMode = selectedSet?.displayConfig.rowLabelVisibilityMode ?? 'ALL';
            display.current.showSeatLabels = selectedSet?.displayConfig.showSeatLabels ?? true;
        }

        display.current?.addSeats(seatData.seats.map(mapSeatDataForDisplay));
    }, [selectedSet, seatData, mapSeatDataForDisplay])

    // Bei Änderungen des ausgewählten Set die Ansicht auf die gesamte Szene anpassen;
    // dies funktioniert aber nur wenn die Sitzplatzdaten bereits geladen wurden.
    useEffect(() => {
        if (selectedSet) {
            const backdrop = selectedSet.displayConfig.frontendBackdrop;
            if (backdrop) {
                // Der generische Asset-Endpunkt ist einzigartig und besitzt nicht den suffix /api/v1
                const base = process.env.REACT_APP_API_BASE_URL?.replace('/api/v1', '')
                const backdropUrl = `${base}/asset/${backdrop.image}`;
                display.current?.setBackdropImage(backdropUrl, backdrop.scale, {x: backdrop.x, y: backdrop.y});
            } else {
                // TODO W2-828 das ist wohl noch buggy wenn upload -> delete -> upload -> stellt alte Textur dar
                //  bei page-reload, wird die Textur korrekt durch das neue Bild geupdated, ist ein reines PixiJS Thema

                // wenn kein frontendBackdrop vorhanden ist sollte ein ggf. dargestelltes Hintergrundbild nicht
                // mehr angezeigt werden
                display.current?.unsetBackdropImage()
            }
        }

        if (isSeatDataFetched) {
            display.current?.fitViewport();
        }
    }, [selectedSet, selectedSetId, isSeatDataFetched])

    return (
        <div className="venue-plan">
            <canvas className={styles.canvas} ref={canvas}/>
        </div>
    );
};

export default VenuePlan;
