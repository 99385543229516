import React, {useCallback, useEffect, useRef} from 'react';
import './ScrollContainer.scss';

type ScrollContainerProps = {
    id: string;
    nodes: React.ReactNode[];
};

const ScrollContainer = ({id, nodes}: ScrollContainerProps): React.ReactElement => {
    let scrollRange: number;
    let targetX: number = 0;
    let targetXAbs: number = 0;
    let animationHandle: number | undefined;

    const dragEl = useRef<HTMLDivElement|null>(null);
    const outerEl = useRef<HTMLDivElement|null>(null);
    const leftIndicatorEl = useRef<HTMLDivElement|null>(null);
    const rightIndicatorEl = useRef<HTMLDivElement|null>(null);

    useEffect(() => {
        updateIndicators();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const memorizedDrag = useCallback((e: MouseEvent) => {
        drag(e);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const memorizedStartDrag = useCallback(() => {
        startDragging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const memorizedStopDrag = useCallback(() => {
        stopDragging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const noScrollRatio = 0.5;

    const startDragging = () => {
        if (!dragEl.current) {
            return;
        }

        scrollRange = dragEl.current.offsetWidth;

        document.removeEventListener('mousemove', memorizedDrag);
        document.addEventListener('mousemove', memorizedDrag);

        if (animationHandle) {
            window.cancelAnimationFrame(animationHandle);
        }
        animationHandle = window.requestAnimationFrame(frameStep);

        updateIndicators();
    };

    const stopDragging = () => {
        if (animationHandle) {
            window.cancelAnimationFrame(animationHandle);
        }
        animationHandle = undefined;
        document.removeEventListener('mousemove', memorizedDrag);
    };

    const drag = (e: MouseEvent) => {
        if (!dragEl.current || !outerEl.current) {
            return;
        }
        const offsetLeft = outerEl.current.getBoundingClientRect().left;
        targetX = (e.pageX - offsetLeft) / scrollRange;
        targetX = (Math.max(Math.min(targetX, 1), 0) - 0.5) * 2;
    };

    const updateIndicators = () => {
        if (!dragEl.current || !leftIndicatorEl.current || !rightIndicatorEl.current) {
            return;
        }
        const scrollWidth = dragEl.current.scrollWidth - dragEl.current.offsetWidth;
        if (dragEl.current.scrollLeft === 0) {
            leftIndicatorEl.current.classList.add('hide');
        } else {
            leftIndicatorEl.current.classList.remove('hide');
        }
        if (dragEl.current.scrollLeft === scrollWidth) {
            rightIndicatorEl.current.classList.add('hide');
        } else {
            rightIndicatorEl.current.classList.remove('hide');
        }
    };

    const frameStep = () => {
        if (!dragEl.current) {
            return;
        }
        if (Math.abs(targetX) > noScrollRatio) {
            const sign = targetX >= 0 ? 1 : -1;
            targetXAbs = Math.pow((targetX - noScrollRatio * sign), 2) * 40 * sign;
            targetXAbs += dragEl.current.scrollLeft;
            dragEl.current.scrollTo({left: Math.round(targetXAbs)});
        }
        updateIndicators();
        animationHandle = requestAnimationFrame(frameStep);
    };

    return (
        <div className="row click-row mt-0 mb-1 overflow-hidden position-relative"
            id={`dragger-outer-${id}`}
            ref={outerEl}
            onMouseOver={memorizedStartDrag}
            onMouseLeave={memorizedStopDrag}
        >
            <div className="indicator indicator-left hide"
                id={`indicator-left-${id}`}
                ref={leftIndicatorEl}
                onTouchStart={memorizedStartDrag}
                onTouchEnd={memorizedStopDrag}
            />
            <div className="indicator indicator-right hide"
                id={`indicator-right-${id}`}
                ref={rightIndicatorEl}
                onTouchStart={memorizedStartDrag}
                onTouchEnd={memorizedStopDrag}
            />

            <div key={`selection-${id}`} className="m-0 row dragger" id={`dragger-${id}`} ref={dragEl}>
                <div className="inner-container row m-0 p-0">
                    {nodes.map((node, _index) => (
                        <div key={`node-${_index}`}
                             className={`scroll-node col mb-1 mt-0 ${_index === nodes.length - 1 ? '' : 'me-1'}`}>
                            {node}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScrollContainer;
