import React, {useEffect} from 'react';
import {TagDefinition} from '../model/Seating';
import './SeatSelectionTextualView.scss';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedSetIdState, selectedSetState} from '../state';
import {useSeatDataQuery} from '../api/queries';

export type SeatSelectionTextualViewProps = {
    toggleSeats: (publicIds: string[]) => void;
};


const findTagColor = (tagDefinitions: TagDefinition[], tagId: string): string =>
    tagDefinitions.find(t => t.id === tagId)?.color ?? '000000';

const findTagName = (tagDefinitions: TagDefinition[], tagId: string): string =>
    tagDefinitions.find(t => t.id === tagId)?.name ?? 'undefined';

export const SeatSelectionTextualView: React.FC<SeatSelectionTextualViewProps> = (
    {toggleSeats}: SeatSelectionTextualViewProps
) => {
    const selectedSetId = useRecoilValue(selectedSetIdState);
    const setSelectedSet = useSetRecoilState(selectedSetState);
    const {data: seatData, error: seatDataError} = useSeatDataQuery(selectedSetId);

    // Gibt es eine Fehler-Response, dann das set zurücksetzen, um "festfressen" zu vermeiden.
    useEffect(() => {
        if (seatDataError) {
            setSelectedSet(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seatDataError]);

    if (!seatData) {
        return null;
    }

    return (
        <div className="container p-0" id="seat-selection">
            <table className="table table-sm">
                <thead>
                <tr>
                    <th scope="col">EventPart</th>
                    <th scope="col">PublicId</th>
                    <th scope="col">Bereich</th>
                    <th scope="col">Reihe</th>
                    <th scope="col">Platz</th>
                    <th scope="col">Tags</th>
                    <th scope="col">Koordinaten</th>
                    <th scope="col">Status</th>
                    <th scope="col">Aktion</th>
                </tr>
                </thead>
                <tbody>
                {seatData.seats.map((seat) => (
                    <tr key={`seat-${seat.publicId}`}>
                        <td>{seat.eventPart}</td>
                        <td>{seat.publicId}</td>
                        <td>{seat.area}</td>
                        <td>{seat.row}</td>
                        <td>{seat.label}</td>
                        <td>
                            {seat.tags.map((tag) => {
                                const tagColor = findTagColor(seatData.tagDefinitions, tag);
                                return (
                                    <div className="tag" data-color={tagColor}
                                         key={`innerseat-${seat.eventPart}-${seat.publicId}-${tag}`}
                                         style={{backgroundColor: '#' + tagColor}}>{findTagName(seatData.tagDefinitions, tag)}
                                    </div>
                                );
                            })}
                        </td>
                        <td>{seat.x}, {seat.y}</td>
                        <td className="td-status">{seat.status}</td>
                        <td>
                            {seat.status !== 'not_available' && (
                                <button className="btn btn-primary-custom btn-sm"
                                        onClick={() => toggleSeats([seat.publicId])}
                                >
                                    {(seat.status === 'selected') ? 'abwählen' : 'auswählen'}
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default SeatSelectionTextualView;
