import {atom, selector} from 'recoil';
import {CartItemsById, ItemForSeat, MatrixConfig, SelectedSet} from '../model/Seating';

/**
 * Das aktuell ausgewählte Set.
 */
export const selectedSetState = atom<SelectedSet | undefined>({
    key: 'selectedSet',
    default: undefined
});

/**
 * Die ID des aktuell ausgewählten Sets.
 */
export const selectedSetIdState = selector<string | undefined>({
    key: 'selectedSetId',
    get: ({get}) => get(selectedSetState)?.setId
});

/**
 * Der aktuelle seatViews Filter
 */
export const selectedSetFilterState = atom<string | 'disabled'>({
    key: 'selectedSetFilter',
    default: ''
});

/**
 * Die aktuelle MatrixConfig aus dem getSets() Endpunkt
 */
export const matrixConfigState = atom<MatrixConfig | undefined>({
    key: 'matrixConfig',
    default: undefined
});

export const countdownState = atom<number | null>({
    key: 'countdown',
    default: null
});


// TODO: in eigenes Modul auslagern, damit zugreifbar über cart.visibleItems o.Ä.

/**
 * Warenkorbposition
 */
export const itemsForSeatsState = atom<ItemForSeat[]>({
    key: 'cart.items',
    default: []
});

export const cartItemsByIdState = selector<CartItemsById>({
    key: 'cart.itemsById',
    get: ({get}) => get(itemsForSeatsState).reduce((acc, i) => {
        acc[i.publicId] = i;
        return acc;
    }, {} as CartItemsById)
});

export const timeSlotSelectionCountState = selector<Record<string, number>>({
    key: 'cart.timeSlotSelectionCount',
    get: ({get}) => get(itemsForSeatsState).reduce((acc, i) => {
        acc[i.row] = (acc[i.row] ?? 0) + 1;
        return acc;
    }, {} as Record<string, number>)
});

export const visibleCartItemsState = selector<ItemForSeat[]>({
    key: 'cart.visibleItems',
    get: ({get}) => get(itemsForSeatsState).filter(i => i.status === 'SELECTED' && !i.removed)
});

export const displayedCartItemsState = selector<ItemForSeat[]>({
    key: 'selection.seatIdsForSelectedSet',
    get: ({get}) => {
        const setId = get(selectedSetIdState);
        const cartItems = get(itemsForSeatsState);

        return cartItems?.filter(i => i.setId === setId);
    }
});
