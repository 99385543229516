import {InteractionMode, InteractionState} from './common';
import {SelectionIdleState} from './selection';
import {PickSeatState} from './picking';

export type {DisplayContext, InteractionMode, InteractionState} from './common';

export function getIdleState(interactionMode: InteractionMode): InteractionState {
    switch (interactionMode) {
        case 'SELECT':
            return new SelectionIdleState(undefined);
        case 'PICK':
        default:
            return new PickSeatState();
    }
}
