import React from 'react';
import './TagDefinitionView.scss';
import {useRecoilValue} from 'recoil';
import {selectedSetIdState} from '../state';
import {useTagDefinitionsQuery} from '../api/queries';

const tinyColor = require("tinycolor2/dist/tinycolor-min");

const TagDefinitionView: React.FC = () => {
    const selectedSetId = useRecoilValue(selectedSetIdState);
    const {data: tagDefinitions} = useTagDefinitionsQuery(selectedSetId);

    if (!tagDefinitions) {
        return null;
    }

    return (
        <div id="tag-definitions" className="w-100 px-2">
            <h4>Legende</h4>
            <div className="flex-tags">
                {tagDefinitions.map((tagDefinition) => (
                    <div className="chip" key={`tagdef-${tagDefinition.id}`}
                         style={{
                             backgroundColor: '#' + tagDefinition.color
                         }}>
                        <div className="text" style={{
                            color: tinyColor(tagDefinition.color).isLight() ? 'black' : 'white'
                        }}>{tagDefinition.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TagDefinitionView;
