import React from 'react';
import './CartView.scss';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import {ItemForSeat} from '../model/Seating';
import {matrixConfigState} from '../state';
import {useRecoilValue} from 'recoil';
import {FormattedDate} from 'react-intl';

type CartViewEntryHeaderProps = {
    item: ItemForSeat;
    removeAction: () => void;
};

const SeatingCartItemHeader: React.FC<CartViewEntryHeaderProps> = ({item, removeAction}) => (
    <>
        <div className="badge bg-secondary-custom set-name w-100">
            {item.setName?.split('\\n').map((line, i) => (
                <span key={i}>{line}<br/></span>
            ))}
        </div>
        <div className="row">
            <div className="col-11">
                <strong>{item.longDates}</strong><br/>
                {item.area && (
                    <span><strong>Bereich</strong> {item.area}, </span>
                )}
                <span><strong>Reihe</strong> {item.row}, </span>
                <span><strong>Platz</strong> {item.label}</span>
            </div>
            <div className="col-1">
                <button type="button" className="btn-close" aria-label="Close" onClick={removeAction}/>
            </div>
        </div>
    </>
);

const PytCartItemHeader: React.FC<CartViewEntryHeaderProps> = ({item, removeAction}) => {

    // Der folgende code funktioniert nur für vollständige selektierte Items,
    // daher hier aussteigen, wenn kein solches in der Hand halten.
    if (item.status === 'PENDING') {
        return null;
    }

    return (
        <>
            <div className="badge bg-secondary-custom set-name w-100">{item.setName}</div>
            <div className="row">
                <div className="col-11">
                    {item.area && (<><strong>{item.area}</strong><br/></>)}
                    <strong><FormattedDate
                        value={item.row.split('|')[0]}
                        year="numeric" month="numeric" day="numeric" weekday="long"
                    /></strong><br/>
                    <strong>{item.row.split('|')[1].replace('-', ' - ')}</strong><br/>
                </div>
                <div className="col-1">
                    <button type="button" className="btn-close" aria-label="Close" onClick={removeAction}/>
                </div>
            </div>
        </>
    );
};

const CartViewEntryHeader: React.FC<CartViewEntryHeaderProps> = props => {
    const matrixConfig = useRecoilValue(matrixConfigState);

    const Header: React.FC<CartViewEntryHeaderProps> =
        matrixConfig?.mode === 'normal'
            ? SeatingCartItemHeader
            : PytCartItemHeader;

    return (
        <div className="toast-header">
            <div className="cart-form-entry-header w-100">
                <Header {...props}/>
            </div>
        </div>
    );
}

export default CartViewEntryHeader;
