import {InteractionManager, Renderer, Sprite} from 'pixi.js';
import {Viewport} from 'pixi-viewport';
import {Point} from '../geometry';

const INITIAL_ZOOM_FACTOR = 1;

export interface SeatDisplayData {
    id: string
    position: Point
    sprite: Sprite
}

export const setupRenderer = (canvas: HTMLCanvasElement): Renderer => {
    return new Renderer({
        view: canvas,
        // TODO: restore auto-resize
        // resizeTo: canvas,
        width: canvas.offsetWidth,
        height: canvas.offsetHeight,
        resolution: window.devicePixelRatio || 1,
        transparent: true
    });
}

export const setupViewport = (screenWidth: number, screenHeight: number, interactionManager: InteractionManager): Viewport => {
    const viewport = new Viewport({
        screenWidth,
        screenHeight,
        passiveWheel: false,
        stopPropagation: true,
        interaction: interactionManager
    });

    // activate plugins
    viewport.drag({mouseButtons: 'left'}).pinch().wheel();

    viewport.setZoom(INITIAL_ZOOM_FACTOR);
    viewport.moveCenter(0, 0);

    return viewport;
}
