import {Seat} from './Seating';
import {createIntl, createIntlCache} from '@formatjs/intl'
import {IntlCache, IntlShape} from 'react-intl';

/**
 * Alle Types, die die PYT-Erweiterung des Seatings betreffen
 */
export interface SelectorTreeNode<T> {
    mapperPayload?: T;
    children: SelectorTreeNode<T>[];
    value: string;
    level: number;
}

export interface PytDates {
    dates: string[];
}

export interface MapperConfigType<T> {
    converters: ((dtoObject: T) => string)[];
}

export type MappableSeat = Pick<Seat, 'row' | 'publicId' | 'status'>;

/**
 * Konverter-Konfiguration für die SelectorTreefactory
 * converters-Array beinhaltet je Baum-Level eine Konverter-Methode
 */
export abstract class MapperConfig<T> {
    protected config: MapperConfigType<T>;
    protected intlCache: IntlCache;
    protected intl: IntlShape;

    protected constructor(locale: string = 'de', config: MapperConfigType<T>) {
        this.config = config;
        this.intlCache = createIntlCache();
        this.intl = createIntl(
            {
                locale: locale,
                defaultLocale: 'en'
            },
            this.intlCache
        );
    }

    get converters(): ((dtoObject: T) => string)[] {
        return this.config.converters;
    }
}

/**
 * Konkrete Mapper-Config für getPytDates-Endpunkt
 * converters-Array beinhaltet je Baum-Level eine Konverter-Methode
 */
export class PytDatesMapperConfig extends MapperConfig<string> {
    constructor(locale: string) {
        super(locale, {
            converters: [
                (dtoObject): string => {
                    const split = dtoObject.split('|');
                    return split.length === 1 ? '' : split[0];
                },
                (dtoObject): string => {
                    return this.intl.formatDate(`${dtoObject.split('|')[1]}`, {year: 'numeric'});
                },
                (dtoObject): string => {
                    return this.intl.formatDate(`${dtoObject.split('|')[1]}`, {month: 'long'});
                },
                (dtoObject): string => {
                    return this.intl.formatDate(`${dtoObject.split('|')[1]}`, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'numeric'
                    });
                }]
        });
    }
}

/**
 * Erzeugt ein rekursives Baum-Objekt aus einem (beliebigen) flachen DTO-Array.
 * Für jeden DTO-Typen wird eine spezifische MapperConfig erwartet
 */
export const generateSelectorTree = <T>(dtos: T[], config: MapperConfig<T>, rootNode: SelectorTreeNode<T>): void => {
    dtos.forEach((dto) => {
        // Die Anzahl der converter bestimmte die Tiefe des Baumes
        const maxDepth = config.converters.length;
        let currentNode = rootNode;

        config.converters.forEach((converterFunc, level) => {
            const value = converterFunc(dto);

            if (value === '') {
                return;
            }

            const foundNode = currentNode.children.find(n => n.value === value);

            if (!foundNode) {
                const newNode: SelectorTreeNode<T> = {
                    value,
                    level: level + 1,
                    children: [],
                    // TODO: mapperPayload ist das DTO selbst, aber nur wenn es sich um ein leaf-node handelt?
                    mapperPayload: level === maxDepth - 1 ? dto : undefined
                };

                currentNode.children.push(newNode);
                currentNode = newNode;
            } else {
                currentNode = foundNode;
            }
        });
    });

}
