import React, {useMemo} from 'react';
import './PytSelection.scss';
import {useRecoilValue} from 'recoil';
import {cartItemsByIdState, selectedSetFilterState, selectedSetIdState} from '../state';
import {useSeatDataQuery} from '../api/queries';
import {forEach, groupBy, isEmpty, map} from 'lodash';
import {parseTimeSlot, TimeSlot} from '../model/timeslot';
import TimeSlotSelect from './TimeSlotSelect';
import log from 'loglevel';

export type PytSelectionProps = {
    toggleSeats: (publicIds: string[]) => void;
};

export const PytSeatSelection: React.FC<PytSelectionProps> = (props: PytSelectionProps) => {
    const selectedSetId = useRecoilValue(selectedSetIdState);
    const selectedSetFilter = useRecoilValue(selectedSetFilterState);
    const {data: seats} = useSeatDataQuery(selectedSetId, selectedSetFilter);
    const cartItemsById = useRecoilValue(cartItemsByIdState);

    const timeSlotsByHour = useMemo<Record<string, TimeSlot[]>>(() => {
        const result: Record<string, TimeSlot[]> = {};

        if (!seats) {
            return result;
        }

        const availableSeats = seats.seats
            .filter(s => s.status === 'available')
            // Sofern wir uns auf die Iterationsreihenfolge von Objekten in JS verlassen (evtl. risky),
            // ist es ausreichende die Plätze nach dem Reihenlabel zu sortieren (quasi ISO-8610 date-strings),
            // um zu gewährleisten, dass die Auswahl der Zeitslots in der korrekten Reihenfolge gerendert
            // werden, basieren auf der hier im Folgenden erzeugten Datenstruktur.
            .sort((a, b) => a.row.localeCompare(b.row))

        const timeSlots: TimeSlot[] = [];

        // Die Plätze nach dem Reihenlabel gruppieren und dies dann zu TimeSlots umsetzen.
        forEach(groupBy(availableSeats, s => s.row), (seats, timeSlotString) => {
            try {
                const timeSlot = parseTimeSlot(timeSlotString);

                timeSlot.availableIds = seats
                    // IDs von aktuell ausgewählten Sitzplätze ausschließen
                    .filter(s => !cartItemsById[s.publicId])
                    .map(s => s.publicId);

                timeSlots.push(timeSlot);
            } catch (err) {
                // Da die Zeitslots lediglich als strings in den Reihenlabels der Plätze codiert sind,
                // besteht das Risiko, dass diese Informationen bspw. durch händische Manipulation
                // nicht (mehr) dem erwarteten Format entsprechen. Um zu vermeiden, dass dies
                // zu einem totalen crash führt solche Fälle einfach ignorieren.
                log.warn(err);
            }
        })

        return timeSlots.filter(t => {
            // slots ohne verfügbare Plätze ausschließen
            return !isEmpty(t.availableIds);
        }).reduce((acc, t) => {
            acc[t.start.getHours()] = [...acc[t.start.getHours()] ?? [], t];
            return acc;
        }, result);
    }, [seats, cartItemsById]);

    if (!seats || selectedSetFilter === 'disabled') {
        return <></>;
    }

    return (
        <>
            {map(timeSlotsByHour, (timeSlots, hour) => (
                <div key={hour}>
                    <div className="mb-2 mx-0 separator-node tertiary">{hour} Uhr</div>
                    <div className="row mx-0">
                        {timeSlots.map(t =>
                            <TimeSlotSelect key={t.id} toggleSeats={props.toggleSeats} timeSlot={t}/>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}

export default PytSeatSelection;
