// RegExp zum matching der in den Reihenbezeichnungen codierten Information zu einem Timeslot.
// Beispiel: "2024-04-01|10:00-10:15"
const TIMESLOT_STRING_REGEX = /^(?<date>\d{4}-\d{2}-\d{2})\|(?<start>\d{2}:\d{2})-(?<end>\d{2}:\d{2})$/;

/**
 * Repräsentiert einen auswählbaren Zeitslot.
 */
export interface TimeSlot {
    id: string;
    start: Date;
    end: Date;
    // Die public-ids der verfügbaren Sitzplätze die dieser Zeitslot repräsentiert.
    availableIds: string[];
}

/**
 * Decodiert einen Zeitslot-string.
 *
 * @param timeSlotString Der zu decodierende string, der Form "2024-04-30|12:34-13:45".
 */
export const parseTimeSlot = (timeSlotString: string): TimeSlot => {
    const match = TIMESLOT_STRING_REGEX.exec(timeSlotString)

    if (!match) {
        throw new Error(`the string ${timeSlotString} is not a valid time slot encoding`);
    }

    const date = match.groups?.['date'];
    const start = match.groups?.['start'];
    const end = match.groups?.['end'];

    return {
        id: timeSlotString,
        start: new Date(`${date}T${start}`),
        end: new Date(`${date}T${end}`),
        availableIds: [],
    }
};
