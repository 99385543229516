import React, {useState} from 'react';
import './CartView.scss';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import {ItemForSeat, ItemForSeatItemDefinition} from '../model/Seating';
import {Form} from 'react-bootstrap';
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import CartViewEntryHeader from './CartViewEntryHeader';
import {debounce} from 'lodash';

type CartItemProps = {
    itemForSeat: ItemForSeat;
    index: number;
    personalizationEnabled: boolean;
    removeCartEntry: (item: ItemForSeat) => void;
    updateSeatSelectionForItemForSeat: (itemForSeat: ItemForSeat) => void;
};

/**
 * Komponente, welche eine Warenkorb Position darstellt.
 *
 * @param itemForSeat - Warenkorbpositionsobjekt
 * @param index - Index der übergebenen Warenkorbposition im Warenkorb
 * @param personalizationEnabled - Angabe ob die Angabe von Personendaten aktiviert ist
 * @param removeCartEntry - Callback zum entfernen einer Warenkorbposition
 * @param updateSeatSelectionForItemForSeat - Callback zum updaten einer Warenkorbposition
 * @constructor
 */
const CartItem: React.FC<CartItemProps> = ({
                                               itemForSeat,
                                               index,
                                               personalizationEnabled,
                                               removeCartEntry,
                                               updateSeatSelectionForItemForSeat
                                           }) => {

    const CART_ITEM_DEBOUNCE = 300;

    /**
     * Wählt eine Leistung im Vorfeld aus, sofern diese entweder bereits im Vorfeld in der API festgelegt ist oder wenn
     * für diese Warenkorbposition nur eine Leistung zur Verfügung steht.
     */
    const getSelectDefaultValue = (): ItemForSeatItemDefinition[] | undefined => {
        if (itemForSeat.selectedItemDefinition) {
            const foundItemDefinition = itemForSeat.itemDefinitions.find((itemDefinition) => {
                return itemDefinition.id === itemForSeat.selectedItemDefinition;
            });

            if (foundItemDefinition) {
                return [foundItemDefinition];
            }
        }

        if (itemForSeat.itemDefinitions === undefined
            || itemForSeat.itemDefinitions.length === 0
            || itemForSeat.itemDefinitions.length > 1) {
            return undefined;
        }

        return [itemForSeat.itemDefinitions[0]];
    };

    const [selectedItemDef, setSelectedItemDef] = useState<ItemForSeatItemDefinition[] | undefined>(getSelectDefaultValue());

    /**
     * onChange-Handler für das Leistungs-Auswahl-Dropdown
     *
     * @param selected
     */
    const onChangeSelectedItemDef = (selected: ItemForSeatItemDefinition[]) => {
        updateSeatSelectionForItemForSeat({
            ...itemForSeat,
            selectedItemDefinition: (selected && selected?.length > 0) ? selected[0].id : undefined
        });
        setSelectedItemDef(selected);
    }

    /**
     * FIXME Typing...
     *
     * onChange-Handler für die Personalisierungseingabefelder einer Leistungsposition
     *
     * @param event
     */
    const onChangePersonalisation = debounce((event) => {
        const value: string = event.target.value;
        // Letztes Element nach split holen
        const name = event.target.name.split('-').pop();

        updateSeatSelectionForItemForSeat({
            ...itemForSeat,
            firstName: name === 'firstName' ? value : itemForSeat.firstName,
            lastName: name === 'lastName' ? value : itemForSeat.lastName
        });
    }, CART_ITEM_DEBOUNCE);

    /**
     * Formattiert eine number in ein Euro-Format.
     *
     * @param price
     */
    function formatPrice(price: number) {
        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(price / 100);
    }

    return (
        <div key={`itemForSeat-${index}`}>
            <div className="d-flex m-lg-1 justify-content-center toast-container">
                <div className="toast cart-form-entry show"
                     data-index={index}
                     aria-live="assertive"
                     aria-atomic="true"
                >
                    <CartViewEntryHeader item={itemForSeat}
                                         removeAction={() => removeCartEntry(itemForSeat)}
                    />
                    <div className="toast-body">
                        <input type="hidden"
                               name={`${index}-seatPublicId`}
                               value={itemForSeat.publicId}
                        />
                        <input type="hidden"
                               name={`${index}-setId`}
                               value={itemForSeat.setId}
                        />
                        <Form.Group className="mb-2 align-left text-start">
                            <div className="clearfix w-100 d-flex">
                                <Typeahead
                                    id={`typeahead-${index}-itemDefinition`}
                                    className="w-100 typeahead"
                                    inputProps={{
                                        readOnly: true,
                                        required: true
                                    }}
                                    onChange={onChangeSelectedItemDef}
                                    multiple={false}
                                    clearButton
                                    placeholder="Bitte auswählen..."
                                    defaultSelected={selectedItemDef}
                                    labelKey={(option: ItemForSeatItemDefinition) => `${option.label}`}
                                    renderMenuItemChildren={(option: ItemForSeatItemDefinition, {text}: any, index: any) => (
                                        <div key={`menuItem-${index}-${option.id}`}
                                             className="typeahead-menuitem">
                                            <div>
                                                <Highlighter search={text}>
                                                    {option.label}
                                                </Highlighter>
                                            </div>
                                            <small>
                                                {formatPrice(option.price)}
                                            </small>
                                        </div>
                                    )}
                                    options={itemForSeat.itemDefinitions}
                                />
                                {selectedItemDef?.[0] && (
                                    <div className={`price-display price-display-${index} badge`}>
                                        {formatPrice(selectedItemDef[0].price)}
                                    </div>
                                )}
                            </div>
                            <Form.Control
                                type="text"
                                className="d-none"
                                name={`${index}-itemDefinition`}
                                id={`itemDefinition-${index}`}
                                defaultValue={
                                    (selectedItemDef === undefined || selectedItemDef[0] === undefined)
                                        ? ''
                                        : selectedItemDef[0].id
                                }
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Bitte wählen Sie eine Leistung
                            </Form.Control.Feedback>
                            <Form.Label
                                id={`label-item-${index}`}
                                className="form-label float-none"
                                htmlFor={`${index}-itemDefinition`}>Leistung *
                            </Form.Label>
                        </Form.Group>

                        {personalizationEnabled && (
                            <>
                                <Form.Group className="mb-2 float-left">
                                    <Form.Control
                                        type="text"
                                        name={`${index}-firstName`}
                                        id={`${index}-firstName`}
                                        defaultValue={itemForSeat.firstName ?? ''}
                                        onChange={onChangePersonalisation}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Bitte geben Sie einen Vornamen an
                                    </Form.Control.Feedback>
                                    <Form.Label
                                        id={`label-firstname-${index}`}
                                        className="form-label"
                                        htmlFor={`${index}-firstName`}>Vorname *
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        name={`${index}-lastName`}
                                        id={`${index}-lastName`}
                                        defaultValue={itemForSeat.lastName ?? ''}
                                        onChange={onChangePersonalisation}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Bitte geben Sie einen Nachnamen an
                                    </Form.Control.Feedback>
                                    <Form.Label
                                        id={`label-lastname-${index}`}
                                        className="form-label"
                                        htmlFor={`${index}-lastName`}>Nachname *
                                    </Form.Label>
                                </Form.Group>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartItem;
