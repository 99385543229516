import {InteractionEvent} from 'pixi.js';
import {Point} from '../../geometry';
import {Scene} from '../scene';
import {DataManager} from '../data';
import {VenuePlanInteractionEvent} from './event';

export const DRAGGING_START_THRESHOLD = 2;

export type InteractionMode =
    | 'ADD'
    | 'PICK'
    | 'SELECT'

export interface DisplayContext {

    readonly interactionMode: InteractionMode;

    readonly scene: Scene;

    readonly dataManager: DataManager;

    dispatch(event: VenuePlanInteractionEvent): void;

}

export interface InteractionState {

    /**
     *
     * @param event
     * @param worldPoint
     */
    onInteraction(context: DisplayContext, event: InteractionEvent, worldPoint: Point): InteractionState;

    /**
     * Callback der aufgerufen wird, wenn der state ausgetauscht werden soll.
     *
     * Konkrete Implementierungen sollte hier evtl. nötige Aufräumarbeiten durchführen,
     * bspw. entfernen von Elementen aus der Anzeigen.
     */
    onEviction(context: DisplayContext): void;

}

export abstract class DraggingState implements InteractionState {

    protected current: Point;

    protected constructor(protected readonly origin: Point) {
        this.current = origin;
    }

    onInteraction(context: DisplayContext, event: InteractionEvent, worldPoint: Point): InteractionState {
        switch (event.type) {
            case 'pointerup':
                return this.finish(context);
            case 'pointermove':
                this.current = event.data.global.clone();
                return this.update(context);
        }

        return this;
    }

    onEviction(context: DisplayContext): void {
        this.abort(context);
    }

    protected abstract update(context: DisplayContext): InteractionState;

    protected abstract finish(context: DisplayContext): InteractionState;

    protected abstract abort(context: DisplayContext): void;

}

export enum MouseButtonIndex {
    LEFT,
    MIDDLE,
    RIGHT
}

export enum PointerType {
    MOUSE = 'mouse',
    PEN = 'pen',
    TOUCH = 'touch'
}

export abstract class AbstractIdleState implements InteractionState {

    onInteraction(context: DisplayContext, event: InteractionEvent, worldPoint: Point): InteractionState {
        const isMouse = event.data.pointerType === PointerType.MOUSE;
        const isLeftButton = event.data.button === MouseButtonIndex.LEFT;

        if (isMouse && isLeftButton) {
            switch (event.type) {
                case 'pointerdown':
                    return this.onPointerDown(context, event.data.global.clone(), event);
                case 'pointerup':
                    return this;
            }
        }

        return this;
    }

    onEviction(context: DisplayContext): void {
        // noop
    }

    abstract onPointerDown(context: DisplayContext, origin: Point, event: InteractionEvent): InteractionState;

}

export abstract class AbstractPointerDownState implements InteractionState {

    constructor(protected origin: Point) {
        //  for parameter properties only
    }

    onInteraction(context: DisplayContext, event: InteractionEvent, worldPoint: Point): InteractionState {
        switch (event.type) {
            case 'pointerup':
                return this.onPointerUp(context);
            case 'pointermove':
                return this.onPointerMove(context, event.data.global.clone());
        }

        return this;
    }

    onEviction(context: DisplayContext): void {
        // noop
    }

    abstract onPointerUp(context: DisplayContext): InteractionState;

    abstract onPointerMove(context: DisplayContext, current: Point): InteractionState;

}
