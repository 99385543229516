import {useQuery} from 'react-query';

import api from './HttpService';
import {useRecoilValue} from 'recoil';
import {selectedSetIdState} from '../state';

export const FALLBACK_QUERY_PARAM = 'fallback';
export const SEAT_SELECTION_ERROR_CODE = 423;

export const SEAT_DATA_INITIAL_REFRESH_DELAY = 1000;
export const SEAT_DATA_REFRESH_INTERVAL = 5000;

/**
 * Die Sitzplatzdaten zu einem bestimmten Set abrufen.
 *
 * Die Daten werden automatisch periodisch refreshed.
 *
 * @param setId Die ID des Set für das die Sitzplatzdaten abgerufen werden sollen.
 * @param filter PYT-Tages-Filter, kann auf "disabled" gesetzt werden, um periodisches Abfragen zu deaktivieren
 */
export const useSeatDataQuery = (setId?: string, filter: string = '') => {
    return useQuery(
        filter.length ? ['seatData', setId, filter] : ['seatData', setId],
        () => {
            return api.getSeatResponse(setId!, filter);
        }, {
            enabled: !!setId && filter !== 'disabled',
            refetchInterval: SEAT_DATA_REFRESH_INTERVAL,
            refetchIntervalInBackground: false,
            retry: 1
        }
    );
}

/**
 * Die Tag-Definitionen zu einem bestimmten Set abrufen.
 *
 * Die Daten werden automatisch periodisch refreshed.
 *
 * @param setId Die ID des Set für das die Tag-Definitionen abgerufen werden sollen.
 */
export const useTagDefinitionsQuery = (setId?: string) => useQuery(['seatData', setId], () => {
    return api.getSeatResponse(setId!, '');
}, {
    enabled: !!setId,
    refetchInterval: SEAT_DATA_REFRESH_INTERVAL,
    refetchIntervalInBackground: false,
    retry: 1,
    // Die query ist die selbe wie für die Sitzplatzdaten (useSeatDataQuery),
    // daher sollte kein separater fetch erfolgen. Es wird lediglich ein
    // anderer Ausschnitt der Daten betrachtet.
    select: seatData => seatData.tagDefinitions
});

export const useAvailableTimeslotsDatesQuery = () => {
    const selectedSetId = useRecoilValue(selectedSetIdState);

    return useQuery<string[]>(
        ['timeslots.availableDates', selectedSetId],
        async () => (await api.getPytDates(selectedSetId!)).dates,
        {
            enabled: !!selectedSetId,
            initialData: []
        }
    );
};
