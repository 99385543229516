import {Point} from './types';

export type PointComparisonMode =
    | 'Y_ASC_X_ASC' // Vergleiche Y-Koordinate aufsteigend, wenn gleich dann X-Koordinate aufsteigend vergleichen.

/**
 * Erzeugt eine comparator function, um "Koordinaten-behaftete" Objekte
 * miteinander zu vergleichen für die Verwendung mit Array.sort().
 *
 * @param mode Modus der verwendet werden soll, um die Koordinaten zu vergleichen.
 */
export function comparePoints<P extends Point>(mode: PointComparisonMode): (a: P, b: P) => number {
    switch (mode) {
        case 'Y_ASC_X_ASC':
            return (a, b) => {
                if (a.y < b.y) return -1;
                if (a.y > b.y) return +1;
                if (a.x < b.x) return -1;
                if (a.x > b.x) return +1;
                return 0;
            };
    }

}
