import React, {useEffect, useState} from 'react';
import {
    ItemForSeat,
    SelectedSet,
    SetsMenuEntry,
    SetsMenuResponse,
    SetsMenuSubEntry,
    VoucherRequest
} from '../model/Seating';
import api from '../api';
import SetsView from '../component/SetsView';
import VoucherView from '../component/VoucherView';
import CartView from '../component/CartView';
import TagDefinitionView from '../component/TagDefinitionView';
import SeatSelection from '../selection/SeatSelection';
import {useRecoilState} from 'recoil';
import {
    countdownState,
    itemsForSeatsState,
    matrixConfigState,
    selectedSetFilterState,
    selectedSetState
} from '../state';
import {useQueryClient} from 'react-query';
import {SEAT_DATA_INITIAL_REFRESH_DELAY} from '../api/queries';
import PytDateSelection from '../selection/PytDateSelection';
import {FormattedNumber, IntlProvider} from 'react-intl';
import {useParams} from 'react-router-dom';
import {isEmpty, remove} from 'lodash';
import log from 'loglevel';

/**
 * Prüft ob die aktuelle Route eine Backend-Route(?) ist, indem geprüft wird, ob der "slug" eine MongoID ist.
 * Der Name muss mit "use" beginnen, da dies anscheinend eine React Hook ist..?
 */
export const useIsBackendCall = (): boolean => {
    const {slug} = useParams();
    // testet slug auf 24-stelligen hex string (Object Id)
    return slug && (/^[a-f\d]{24}$/i).test(slug);
}

/**
 * Komponente, welche das Seating im Frontend abbildet, samt Eventteilselektion, Saalplan und Warenkorb.
 *
 * @constructor
 */
const SeatingShopPage: React.FC = () => {
    const {slug} = useParams();

    const isBackendCall: boolean = useIsBackendCall();
    const queryClient = useQueryClient();

    // Menge an verfügbaren Saalplänen/Eventteil und Combo-Saalplänen/Eventteil
    const [sets, setSets] = useState<SetsMenuEntry[]>([]);

    // Matrixkonfiguration
    const [matrixConfig, setMatrixConfig] = useRecoilState(matrixConfigState);

    // Das ausgewählte "Set" aka Saalplan/Eventteil bzw. Combo-Saalplan/Eventteil
    const [selectedSet, setSelectedSet] = useRecoilState(selectedSetState);

    // selectedSetFilter ist der speziell für PYT-Modi benötigte Tages-Filter, er wird
    // für den PYT-Seating-Modus zunächst auf "disabled" gesetzt, wodurch das periodische
    // Abfragen der SeatViews / seatData deaktiviert wird
    const [selectedSetFilter, setSelectedSetFilter] = useRecoilState(selectedSetFilterState);

    // itemsForSeats ist die updateSeatSelection Antwort, enthält mögliche Leistungen
    // pro set / publicId und beinhaltet initial teilweise redundante Daten, um Sub-Set-Auswahl
    // zu prüfen. itemsForSeats werden im Warenkorb angezeigt, nachdem alle comboSetId != null Einträge
    // entfernt wurden
    const [cartItems, setCartItems] = useRecoilState(itemsForSeatsState);

    // Link/Url zu Seating-Stylesheets, wird dynamisch injected
    const [stylesheetLink, setStylesheetLink] = useState<string | null>(null);

    // Countdown der aktuellen Session
    const [countdown, setCountdown] = useRecoilState(countdownState);

    /**
     * Cleared wohl eine Session?
     *
     * @param callback
     */
    const clearSession = (callback: () => void = () => {
    }): void => {
        api.postClearSession().then(() => {
            setSelectedSet(undefined);
            setCartItems([]);
            callback();
        });
    };

    /**
     * Lädt Sets-Menü und globale Config nach
     */
    const reloadSets = (): Promise<SetsMenuResponse> => {
        const getSets = api.getSets();
        getSets.then(data => {
            setSets(data.sets);
            setMatrixConfig(data.config);

            // für alle PYT-Modi getseatviews refresh zunächst deaktivieren
            if (data.config.mode !== 'normal' && selectedSetFilter === '') {
                setSelectedSetFilter('disabled');
            }
        });
        return getSets;
    };

    /**
     * TODO Dokumentation + ggf. refactoring,
     *    wohl dasselbe wie findSet(), nur eine Methode von beiden benutzen!
     *
     * @param setId
     */
    const findSetsMenuSubEntryBySetId = (setId: string): SetsMenuSubEntry | undefined => {
        if (sets.length === 0) {
            return undefined;
        }

        // FIXME [].find() ?
        let setSubMenuEntry = undefined;
        sets.forEach((_set) => {
            if (_set.entries && _set.entries.length > 0) {
                _set.entries.forEach((_entry) => {
                    if (_entry.setId === setId) {
                        setSubMenuEntry = _entry;
                        return;
                    }
                });
            }
        });

        return setSubMenuEntry;
    }

    /**
     * Wählt das aktuelle set anhand einer setId aus
     *
     * @param setId
     */
    const selectSet = (setId: string): void => {
        const setEntry = findSetsMenuSubEntryBySetId(setId)
        const selectedCardDto: SelectedSet = {
            setId: setId,
            displayConfig: setEntry?.displayConfig ?? {
                rowLabelVisibilityMode: 'ALL',
                showSeatLabels: true
            }
        }

        setSelectedSet(selectedCardDto);
    };

    /**
     * // TODO Dario 06.07.23
     *
     * Einen Platz an- oder abwählen
     * Wird keine setId übergeben, wird die setId aus dem selectedSeat benutzt.
     *
     * @param publicIds
     */
    const toggleSeats = (publicIds: string[]) => {
        const seats = cartItems.map(i => ({...i}));

        publicIds.forEach(publicId => {
            const setId = selectedSet?.setId;

            if (!setId || !publicId) {
                return;
            }

            // Wenn der Platz nicht entfernt werden konnte, dann war er
            // zuvor nicht enthalten, also müssen wir ihn hinzufügen.
            if (isEmpty(remove(seats, s => setId === s.setId && publicId === s.publicId))) {
                seats.push({
                    status: 'PENDING',
                    area: '',
                    eventPart: '',
                    itemDefinitions: [],
                    label: 'ADDED',
                    row: 'JUST',
                    setId: setId,
                    publicId: publicId
                });
            }
        });

        updateCartOptimistic(seats);
    };

    /**
     * Updated die SeatSelection mit einem übergebenen ItemForSeat (Warenkorbposition für einen Platz mit
     * Leistungsdefinition und Personendaten).
     *
     * @param itemForSeat
     */
    const updateCartItem = (itemForSeat: ItemForSeat) => {
        const items = [...cartItems];

        const index = items.findIndex(s =>
            s.comboSetId === null &&
            s.publicId === itemForSeat.publicId
        );

        items[index] = {...itemForSeat};

        updateCartOptimistic(items);
    }

    /**
     * Aktualisiert die SeatSelection
     * - Baut itemsForSeats / Warenkorb auf
     * - Reset des timeouts
     *
     *  FIXME: ggf umbenennen/umbauen
     */
    const initCart = () => {
        api.initCart(cartItems).then(data => {
            setCartItems(data);

            // reset timeout
            if (matrixConfig && !isEmpty(data.length)) {
                setCountdown(matrixConfig.timeout * 60);
            }
        });
    };

    const updateCartOptimistic = async (newCartItems: ItemForSeat[]) => {
        const oldCartItems = [...cartItems];

        // update client-seitig optimistisch übernehmen
        setCartItems(newCartItems);

        try {
            // Den neuen server-seitigen Zustand übernehmen
            setCartItems(await api.updateCart(newCartItems));

            // Bei erfolgreicher Änderung der Auswahl wird der timout zurückgesetzt
            if (matrixConfig) {
                setCountdown(matrixConfig.timeout * 60);
            }
        } catch (_) {
            // Im Fehlerfall, bspw. bei Konflikt mit der Auswahl eines anderen Benutzers,
            // die Änderung verwerfen und den vorherigen Zustand wiederherstellen.
            setCartItems(oldCartItems);
        }
    }

    /**
     * Entfernt ein Item aus dem Warenkorb.
     */
    const removeCartEntry = (item: ItemForSeat): void => {
        log.debug('removeCartEntry:', {...item});

        const index = cartItems.findIndex(i => i === item);

        if (index > -1) {
            const items = [...cartItems]
            items[index] = {...item, removed: true};

            updateCartOptimistic(items);
        }
    };

    /**
     * Sendet einen Gutschein-Code und aktualisiert nach erfolgereicher Einlösung die Sets
     * und mögliche Warenkorb-Leistungen
     *
     * @param event
     */
    const submitCode = (event: any): void => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const voucherDto: VoucherRequest = {
            code: formData.get('code')?.toString() ?? ''
        }

        api.postCode(voucherDto)
            .then(reloadSets)
            .then(initCart);
    };

    /**
     * Sendet den gesamten Warenkorb ab für den Übergang in die Shopstrecke
     *
     * TODO Fehlerbehandlung User-Feedback
     */
    const submitCart = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (!isEmpty(cartItems)) {
            api.postSubmit().then(data => {
                window.location.href = `${process.env.REACT_APP_SHOP_REDIRECT_URL}/${api.getSlug()}/${data.orderId}`;
            });
        }
    };

    /**
     * Prüft, ob die maximal erlaubte Anzahl zu buchender Sitze unterschritten bleibt.
     * Gibt true zurück, wenn aud Backend aufgerufen oder wenn max nicht definiert ist.
     */
    const belowMaxQuantity = (): boolean => {
        return isBackendCall
            || !matrixConfig?.maxQuantity
            || matrixConfig.maxQuantity > cartItems.length;
    }

    /**
     * FIXME In Komponente auslagern
     *
     * Rendert den Header-Bereich des Saalplans ausgehend von der Matrixkonfiguration
     */
    const renderVenueArea = () => {
        if (!matrixConfig) {
            return null;
        }

        return (
            <>
                {matrixConfig?.mode === 'normal'
                    ? <>
                        <SetsView sets={sets} selectCard={selectSet}/>
                        {selectedSet && (
                            <div className="container-fluid row g-2 mt-2 mb-4">
                                <div className="p-0 pb-2 g-2" id="seat-view-container">
                                    <SeatSelection toggleSeats={toggleSeats}/>
                                    <TagDefinitionView/>
                                </div>
                            </div>
                        )}
                    </>
                    : <>
                        <div id="pyt-selection">
                            <h2 className="pb-0 px-1">Termin auswählen oder Gutschein kaufen</h2>
                            <p>
                                Bitte beachte, dass dir im Kalender nur verfügbare Einlasszeiten für
                                dein ca. 90-minütiges Erlebnis im HOUSE OF MAGIC angezeigt werden.
                            </p>
                            <p>
                                Rollstuhlfahrer/innen und Personen mit Behinderung mit entsprechender
                                B-Berechtigung (Nachweis durch den Ausweis) wenden sich bitte unter
                                Angabe ihres Wunschslots per Mail an&nbsp;
                                <a href="mailto:tickets@houseofmagic.de">
                                    tickets@houseofmagic.de
                                </a>.
                            </p>
                            <p>
                                Verschenke mit unseren <strong>
                                <a href="https://tickets.leipziger-messe.de/houseofmagic-gutscheine"
                                   target="_blank"
                                   rel="noreferrer"
                                >Ticket-Gutscheinen</a>
                            </strong> ein unvergessliches Erlebnis im HOUSE OF MAGIC.
                            </p>
                        </div>
                        {selectedSet && (
                            <div className="container-fluid row g-2 mt-0 mb-4">
                                <PytDateSelection toggleSeats={toggleSeats}/>
                            </div>
                        )}
                    </>
                }
                {!belowMaxQuantity() &&
                    <div className="seat-selection-invalid">
                        <div className="card">
                            Die Maximalbestellmenge ist erreicht.
                            Es können keine weiteren Plätze ausgewählt werden.
                        </div>
                    </div>
                }
            </>
        );
    }

    // Initialer useEffect. Stylesheet wird injected, sowie der api der Pfad aus ReactRouter mitgeteilt.
    useEffect(() => {
        if (slug === undefined) {
            return;
        }

        setStylesheetLink(`${process.env.REACT_APP_API_BASE_URL}/frontend/shop/${slug}/styling-vars?t=${(new Date().getTime())}`);
        api.setSlug(slug);

        // FIXME missing dependecy
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sets.length) {
            initCart();
        }

        // FIXME missing dependecy
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matrixConfig]);

    useEffect(() => {
        if (selectedSet === undefined) {
            reloadSets();
        }

        // FIXME missing dependecy
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSet]);

    // Sobald die Daten der Sets (neu) geladen wurden die bestehende Auswahl wiederherstellen,
    // oder ggf. die einzige mögliche Auswahl standardmäßig auswählen.
    useEffect(function selectSetOnLoad() {
        if (!selectedSet) {
            // nur ein set, dann dieses auswählen
            if (sets.length === 1 && sets[0].entries.length === 1) {
                selectSet(sets[0].entries[0].setId);
            }
            return;
        }
        selectSet(selectedSet.setId);
        // Explizit keine weiteren Abhängigkeiten, da wirklich nur auf eine
        // Änderung der aktuell geladenen Sets reagiert werden soll.

        // FIXME missing dependecy
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sets]);

    // Werden itemsForSeats geändert (Antwort SeatSelection), dann den seatData ReactQuery neustarten
    useEffect(() => {
        queryClient.cancelQueries(['seatData'], {active: true});

        const restartTimer = setTimeout(
            () => queryClient.refetchQueries(['seatData'], {active: true}),
            SEAT_DATA_INITIAL_REFRESH_DELAY
        );

        return () => {
            clearTimeout(restartTimer);
        };

        // FIXME missing dependecy
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);

    return (
        <IntlProvider locale="de" defaultLocale="en">
            {stylesheetLink && (<link rel="stylesheet" type="text/css" href={`${stylesheetLink}`}/>)}
            <div className="fixed-top">
                <header className="app-header">
                    <div className="container p-0">
                        <div className="row">
                            <div className="col col-2 text-start">
                                <div className="online-logo"/>
                            </div>
                            <div className="col col-2 offset-8 text-end">
                                <div className="dachmarken-logo"
                                     style={{content: `url(${process.env.REACT_APP_SHOP_REDIRECT_URL}/img/LM_Logo_color_dt.png)`}}
                                />
                                {matrixConfig?.mode === 'normal' && (
                                    <div className="dachmarken-logo-mobile"
                                         style={{content: `url(${process.env.REACT_APP_SHOP_REDIRECT_URL}/img/lm_logo_notext.jpg)`}}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </header>
                <nav className="navbar navbar-expand navbar-light">
                    <ul className="navbar-nav container p-0">
                        <li className="nav-item col-md-2 ">
                            <a className="nav-link"
                               href={`${process.env.REACT_APP_SHOP_REDIRECT_URL}/${api.getSlug()}/legal`}
                            >
                                AGB/Datenschutz
                            </a>
                        </li>
                        <li className="nav-item col-md-2 ">
                            <a className="nav-link"
                               href={`${process.env.REACT_APP_SHOP_REDIRECT_URL}/${api.getSlug()}/contact`}
                            >
                                Kontakt/Impressum
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className={`countdown-bar ${cartItems.length > 0 ? 'd-block' : ''}`}>
                    <div className="col" id="countdown">
                        {countdown && cartItems.length > 0 && <>
                            <span>Reserviert für: </span>
                            <FormattedNumber value={Math.floor(countdown / 60)} maximumFractionDigits={0}/>:
                            <FormattedNumber value={countdown % 60}
                                             minimumIntegerDigits={2}
                                             maximumFractionDigits={0}
                            />
                            <span> Min.</span>
                        </>}
                    </div>
                </div>
            </div>
            <main>
                <div className="container">
                    <div className="container-fluid p-0">
                        <div className="row d-lg-none px-1">
                            <VoucherView submitCode={submitCode}/>
                        </div>
                        <div className="row content-row">
                            <div className="col p-0 m-0 left-area">
                                {renderVenueArea()}
                            </div>
                            <div className="col col-3 p-0 right-area">
                                <div className="d-none d-lg-block">
                                    <VoucherView submitCode={submitCode}/>
                                </div>

                                <CartView removeCartEntry={removeCartEntry}
                                          submitCart={submitCart}
                                          clearSession={clearSession}
                                          updateSeatSelectionForItemForSeat={updateCartItem}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </IntlProvider>
    );
}

export default SeatingShopPage;
